/**
 * ajuster textarea auto height
 * @author Lassaad Sassi
 */

import { Directive, ElementRef, HostListener, Input, AfterContentChecked } from '@angular/core';
import { OnInit } from '@angular/core';

@Directive({
  selector: '*[autosize]'
})

// tslint:disable-next-line: directive-class-suffix
export class Autosize implements OnInit, AfterContentChecked {

  maxheight: any;
  @Input() autosize: any;

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjuster();
  }
  constructor(public element: ElementRef) {
  }

  ngOnInit() {
    this.maxheight = this.autosize !== '' ? this.autosize : '500px';
  }

  ngAfterContentChecked(): void {
    this.adjuster();
  }
  adjuster(): void {
    this.element.nativeElement.style.maxHeight = this.maxheight;
    //this.element.nativeElement.style.height = 'auto';
    this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight+'px';
  }
}
