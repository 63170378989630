import { Component, Input, OnInit } from '@angular/core';
import { CompanyViewerComponent } from '../../modals/company-viewer/company-viewer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss']
})
export class CompanyCardComponent implements OnInit {
  @Input() company;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  viewDetails(company){
    const modalRef = this.modalService.open(
      CompanyViewerComponent, {
      size: 'md',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.company = company;
    modalRef.componentInstance.veiwType = 'company'
    modalRef.result.then((result) => {
    });
  }

}
