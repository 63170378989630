import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'role',
  pure: false
})
export class RolePipe implements PipeTransform {
  roles: any = [
    {
      label: this.translate.instant('ACP_SUBSCRIBER'),
      value: 'ACP_SUBSCRIBER'
    },
    {
      label: this.translate.instant('ADMIN'),
      value: 'ADMIN'
    }
  ];

  constructor(
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang)
        this.roles = [
          {
            label: this.translate.instant('ACP_SUBSCRIBER'),
            value: 'ACP_SUBSCRIBER'
          },
          {
            label: this.translate.instant('ADMIN'),
            value: 'ADMIN'
          }
        ];

    });
  }

  transform(value: string) {
    return value ? this.roles.find(role => role.value == value)?.label : '';
  }

}
