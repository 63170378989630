import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { PaymentService } from 'src/app/portal/components/configuration/services/payment.service';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss'],
})
export class SuccessPaymentComponent implements OnInit {
  paymentRef: any;
  status = '';
  timer = 10;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['payment_ref']) {
        this.paymentRef = queryParams['payment_ref'];
        this.paymentCheck(this.paymentRef);
      } else {
        this.router.navigate(['/authentication']);
      }
    });
  }

  paymentCheck(ref) {
    this.paymentService.checkPayment(ref).subscribe(
      (res) => {
        if (res.status == 200) {
          this.status = 'SUCCESS';
          setInterval(() => {
            this.timer--;
          }, 1000);
          setTimeout(() => {
            this.router.navigate(['/authentication']);
          }, 10000);
        } else {
          this.status = 'PENDING';
        }
      },
      (error) => {
        if (error.status == 404) {
          this.status = 'EXPIRED';
          this.router.navigate(['/payment/failed'], {
            queryParams: { payment_ref: ref },
          });
        } else {
          this.status = 'FAILED';
          this.router.navigate(['/payment/failed'], {
            queryParams: { payment_ref: ref },
          });
        }
      }
    );
  }

  reload() {
    window.location.reload();
  }
}
