import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import countryCR from '../../../assets/data-json/countrys.json';
import sectorCR from '../../../assets/data-json/sector.json';
import genderCR from '../../../assets/data-json/gender.json';

@Pipe({
  name: 'crValue',
  pure: false
})
export class CrValuePipe implements PipeTransform {

    public country: any[] = countryCR;
    public sector: any[] = sectorCR;
    public gender: any[] = genderCR;

  constructor(private translate: TranslateService) { }

  transform(value: any, name?: string): any {
    let curentLang = localStorage.getItem('lang');    
    return value ? (this[name]?.find(item => item?.code == value)?.values)?.find(val => val?.language == curentLang)?.name : '';
  }

}
