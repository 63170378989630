import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpCenterComponent } from './help-center.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HelpFormComponent } from './components/help-form/help-form.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [HelpCenterComponent, HelpFormComponent],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule
  ],
  exports: [HelpCenterComponent]
})
export class HelpCenterModule { }
