import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TRANSLATION_LANGUAGES, DEFAULT_LANGUAGE, setLocale } from '../config/translate.config';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  userLang: string;

  constructor(
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient
  ) { }

  public setLanguage(userLang?: string) {

    if (userLang != null) {
      this.userLang = userLang;
    } else {
      const navigatorLanguage: string = isPlatformServer(this.platformId) ? '' : navigator.language.split('-')[0];
      this.userLang = localStorage.getItem('lang') || navigatorLanguage;
    }

    localStorage.setItem('lang', TRANSLATION_LANGUAGES.test(this.userLang) ? this.userLang : DEFAULT_LANGUAGE);
    this.translate.setDefaultLang(this.userLang);
    this.translate.use(this.userLang);
    setLocale(this.userLang);
  }
}
