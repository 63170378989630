import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CRvalues } from '../models/cr.model';

@Pipe({
  name: 'crFormat'
})
export class CrFormatPipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  getLabelCrWithLanguage(values: CRvalues[]) {
    if (localStorage.getItem('lang')) {
      return values.find(value => value.language == localStorage.getItem('lang')).name
    } else {
      return values.find(value => value.language == this.translate.getDefaultLang()).name
    }
  }

  transform(value: any): any {
    return value.map(item => {
      return {
        code: item.code,
        name: this.getLabelCrWithLanguage(item.values)
      }
    });
  }

}
