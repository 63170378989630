<header
  class="header d-block"
  id="site-header"
  *ngIf="connectedUser.remainingDays != -1"
>
  <div class="header-content-wrapper">
    <a class="logo">
      <div class="img-wrap">
        <img src="assets/svg-icons/Website.png" alt="ACP" />
      </div>

      <i (click)="toggleMenu($event)" class="fas fa-bars menu-mob"></i>
    </a>

    <div class="main-menu-header">
      <ul class="mb-0">
        <li>
          <a [routerLink]="'/account/home'" routerLinkActive="active">
            <i class="fas fa-home-alt"></i>
            <span class="left-menu-title">Home</span>
          </a>
        </li>
        <li>
          <a [routerLink]="'/account/directory'" routerLinkActive="active">
            <i class="fas fa-address-card"></i>
            <span class="left-menu-title">{{ "DIRECTORY" | translate }}</span>
          </a>
        </li>
        <li>
          <a [routerLink]="'/account/opportunities'" routerLinkActive="active">
            <i class="fas fa-briefcase"></i>
            <span class="left-menu-title">{{
              "OPPORTUNITIES" | translate
            }}</span>
          </a>
        </li>
        <li>
          <a [routerLink]="'/account/events'" routerLinkActive="active">
            <i class="fas fa-calendar-star"></i>
            <span class="left-menu-title">{{ "EVENTS" | translate }}</span>
          </a>
        </li>
        <li>
          <a [routerLink]="'/account/chat'" routerLinkActive="active">
            <i class="fas fa-comments"></i>
            <span class="left-menu-title">{{ "CHAT" | translate }}</span>
            <span
              *ngIf="unseenMessageCount > 0"
              class="label-avatar bg-primary"
              >{{ unseenMessageCount < 99 ? unseenMessageCount : "99+" }}</span
            >
          </a>
        </li>
      </ul>
    </div>

    <div class="control-block">
      <div class="author-page author vcard inline-items more">
        <div class="author-thumb">
          <img
            alt="author mr-0"
            [src]="connectedUser?.profilePicture"
            class="avatar"
          />
          <span class="icon-status online"></span>
          <div class="more-dropdown more-with-triangle">
            <div class="mCustomScrollbar" data-mcs-theme="dark">
              <div class="ui-block-title ui-block-title-small">
                <h6 class="title">
                  {{ connectedUser?.first_name }} {{ connectedUser?.last_name }}
                </h6>
              </div>

              <ul class="account-settings">
                <li>
                  <a [routerLink]="'/portal/settings'">
                    <i class="far fa-user"></i>
                    <span>{{ "PROFILE" | translate }}</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="'/portal/configuration/custom-list'">
                    <svg class="olymp-settings-icon">
                      <use
                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-settings-icon"
                      ></use>
                    </svg>
                    <span>{{ "CONFIGURATION" | translate }}</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="'/portal/events'">
                    <svg class="olymp-calendar-icon">
                      <use
                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-calendar-icon"
                      ></use>
                    </svg>
                    <span>{{ "EVENTS" | translate }}</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="" (click)="logout()">
                    <svg class="olymp-logout-icon">
                      <use
                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-logout-icon"
                      ></use>
                    </svg>
                    <span>{{ "LOGOUT" | translate }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <app-header-language-portal></app-header-language-portal>

      <app-help-center></app-help-center>
    </div>
  </div>
  <app-warning-subscription-bar
    *ngIf="
      this.connectedUser.remainingDays > -1 &&
      this.connectedUser.remainingDays < 31 &&
      this.connectedUser.remainingDays != null
    "
  ></app-warning-subscription-bar>
</header>
