import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loader-dots',
  templateUrl: './loader-dots.component.html',
  styleUrls: ['./loader-dots.component.scss']
})
export class LoaderDotsComponent implements OnInit {
  @Input() isLoading = false;
  @Input() label;
  @Input() isDisabled;
  @Input() classList;
  @Input() icon;
  @Input() iconType;
  @Input() type = 1;
  @Output() clicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
