<button [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 1" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    <svg *ngIf="iconType == 'svg'" class="olymp-close-icon"><use [attr.xlink:href]="icon"></use></svg>
    <i *ngIf="iconType == 'fontawesome'" [class]="icon"></i>
    {{label}} <span class="loading loading-1"> </span>
</button>

<button [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 2" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    {{label}} <span class="loading loading-2"> </span>
</button>

<button [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 3" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    {{label}} <span class="loading loading-3"> </span>
</button>

<button [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 4" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    {{label}}
    <span class="loading loading-4">
        <span class="loading-4-dot loading-4-span1"></span>
        <span class="loading-4-dot loading-4-span2"></span>
        <span class="loading-4-dot loading-4-span3"></span>
    </span>
</button>

<button [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 5" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    {{label}} <span class="loading loading-5"> </span>
</button>

<button [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 6" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    {{label}} <span class="loading loading-6"> </span>
</button>

<button [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 7" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    {{label}} <span class="loading loading-7"> </span>
</button>

<button style="border-radius: 0px" [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 8" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    {{label}}
    <span class="loading loading-8">
        <span class="loading-8-span1"></span>
        <span class="loading-8-span2"></span>
        <span class="loading-8-span3"></span>
        <span class="loading-8-span4"></span>
    </span>
</button>

<button style="border-radius: 0px" [class]="classList" [ngClass]="{'button--loading': !isLoading}" *ngIf="type == 9" [disabled]="isLoading || isDisabled" (click)="clicked.emit()">
    {{label}}
    <span class="loading loading-9">
        <span class="loading-9-span1"></span>
        <span class="loading-9-span2"></span>
        <span class="loading-9-span3"></span>
        <span class="loading-9-span4"></span>
    </span>
</button>