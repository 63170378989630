<article class="hentry blog-post">
  <div class="post-thumb">
    <a (click)="openNews(news)" style="cursor: pointer">
      <img
        [src]="news?.image ? news.image : 'assets/img/post1.jpg'"
        alt="photo"
      />
    </a>
  </div>

  <div class="post-content">
    <div class="card-title" *ngIf="adminView">
      <div>
        <a
          class="post-category bg-blue-light mb-0"
          [ngClass]="{
            'status-SHOW': news.status,
            'status-HIDE': !news.status
          }"
        >
          {{ news.status ? ("PUBLISHED" | translate) : ("MASKED" | translate) }}
        </a>
      </div>
      <div class="super-header">
        <div class="more">
          <svg class="olymp-three-dots-icon">
            <use
              xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"
            ></use>
          </svg>
          <ul class="more-dropdown">
            <li>
              <a class="cursor-pointer" (click)="updateEvent()">{{
                "EDIT" | translate
              }}</a>
            </li>
            <li>
              <a class="cursor-pointer" (click)="deleteEvent()">{{
                "DELETE" | translate
              }}</a>
            </li>
            <li>
              <a class="cursor-pointer" (click)="toggleStatus()">{{
                news.status ? ("HIDE" | translate) : ("PUBLISH" | translate)
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="post-title">
      <a (click)="openNews(news)" class="h4 mb-0" style="cursor: pointer">{{
        news?.title | crTranslator
      }}</a>
    </div>

    <!-- <div class="description">
      <p class="mb-0">
        {{
          (news?.description | crTranslator).length > 99
            ? (news?.description | crTranslator).slice(0, 97) + "..."
            : (news?.description | crTranslator)
        }}
      </p>
    </div> -->
  </div>
</article>
