import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ExpirationSessionComponent } from '../modals/expiration-session/expiration-session.component';
import { ToastService } from '../services/toast.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastService: ToastService,private modalService: NgbModal) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;                        
                        if(error.status == 403 && !request.url.includes('/wp-json/jwt-auth/v1/token')){
                            this.modalService.dismissAll();
                            setTimeout(() => {
                                this.expirationModal()
                            }, 1000);
                        }
                    }
                    return throwError(errorMessage);
                })
            )
    }

    expirationModal() {
        if(!document.querySelector(".qusgbxjzbwqlybqo")){
            const modalRef = this.modalService.open(
                ExpirationSessionComponent, {
                size: 'md qusgbxjzbwqlybqo',
                backdrop: 'static',
                centered: true
            });
            modalRef.result.then((result) => {
                if (result && result != "Cross click") {
                    this.router.navigateByUrl('/authentication');
                }
            });
        }
    }
}