import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'crTranslator',
  pure: false
})
export class CrTranslatorPipe implements PipeTransform {
  private userLang: string = localStorage.getItem('lang');

    constructor(private translateService: TranslateService) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            if (event.lang)
                this.userLang = event.lang;

        });
    }

  transform(value: any) {    
    if (value && value.length) {
        if (value.find(item => item.language == this.userLang)?.value) {
            return value.find(item => item.language == this.userLang).value;
        }
        else {
            return value[0].value
        }
    }
  }

}
