import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCardComponent } from './components/event-card/event-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EventCardAdminComponent } from './components/event-card-admin/event-card-admin.component';
import {
  NgbCarouselConfig,
  NgbCarouselModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CropperModalComponent } from './components/cropper-modal/cropper-modal.component';
import { UploadFilesService } from './services/upload-files.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastService } from './services/toast.service';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { UserPipe } from './pipes/user.pipe';
import { UserService } from './services/user.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { PublicFilesService } from './services/public-files.service';
import { ExportService } from './services/export.service';
import { CardSkeletonComponent } from './components/card-skeleton/card-skeleton.component';
import { AuthGuard } from './guard/auth.guard';
import { HttpErrorInterceptor } from './interceptor/jwt.interceptor';
import { ExpirationSessionComponent } from './modals/expiration-session/expiration-session.component';
import { ImgCropperComponent } from './components/img-cropper/img-cropper.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { LoaderDotsComponent } from './components/loader-dots/loader-dots.component';
import { SharedService } from './services/shared.service';
import { TranslateModule } from '@ngx-translate/core';
import { TRANSLATE_LOADER_CONFIGS } from './config/translate.config';
import { LanguageService } from './services/language.service';
import { CrFormatPipe } from './pipes/cr-format.pipe';
import { ConvertDatePipe } from './pipes/convert-date.pipe';
import { TranslatedDescriptionPipe } from './pipes/translated-description.pipe';
import { CarrouselComponent } from './components/carrousel/carrousel.component';
import { MediaViewerComponent } from './modals/media-viewer/media-viewer.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { UserViewerComponent } from './modals/user-viewer/user-viewer.component';
import { Autosize } from './directives/autosize.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { CrValuePipe } from './pipes/cr-value.pipe';
import { TrancateTimePipe } from './pipes/trancate-time.pipe';
import { AuthEventGuard } from './guard/auth-event.guard';
import { AuthAcpGuard } from './guard/auth-acp.guard';
import { LabelTraductionComponent } from './modals/label-traduction/label-traduction.component';
import { CrTranslatorPipe } from './pipes/cr-translator.pipe';
import { LinkPatternPipe } from './pipes/link-pattern.pipe';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { BrightnessPipe } from './pipes/image-brightness.pipe';
import { CompanyViewerComponent } from './modals/company-viewer/company-viewer.component';
import { SubscriptionFormComponent } from './modals/subscription-form/subscription-form.component';
import { TagInputModule } from 'ngx-chips';
import { ThumbnailsCarouselComponent } from './components/thumbnails-carousel/thumbnails-carousel.component';
import { RolePipe } from './pipes/role.pipe';
import { SelectCompanyComponent } from './modals/select-company/select-company.component';
import { CompanyCardComponent } from './components/company-card/company-card.component';
import { FirstLoginFormComponent } from './modals/first-login-form/first-login-form.component';
import { StatusPipe } from './pipes/status.pipe';
import { SubscribedEventGuard } from './guard/subscribed-event.guard';
import { NewsDetailsComponent } from './modals/news-details/news-details.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { AreaChartComponent } from './components/charts/area-chart/area-chart.component';
import { WarningSubscriptionBarComponent } from './components/warning-subscription-bar/warning-subscription-bar.component';

const COMPONENTS = [
  EventCardComponent,
  EventCardAdminComponent,
  CropperModalComponent,
  CardSkeletonComponent,
  ExpirationSessionComponent,
  ImgCropperComponent,
  LoaderDotsComponent,
  CarrouselComponent,
  MediaViewerComponent,
  UserCardComponent,
  UserViewerComponent,
  LabelTraductionComponent,
  NewsCardComponent,
  CompanyViewerComponent,
  SubscriptionFormComponent,
  ThumbnailsCarouselComponent,
  SelectCompanyComponent,
  CompanyCardComponent,
  FirstLoginFormComponent,
  NewsDetailsComponent,
  LanguageSelectorComponent,
  BarChartComponent,
  AreaChartComponent,
  WarningSubscriptionBarComponent,
];

const ENTRY_COMPONENTS = [ImgCropperComponent];

const DIRECTIVES = [Autosize, InfiniteScrollDirective];

const MODULES = [
  CommonModule,
  RouterModule,
  NgbModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  ImageCropperModule,
  NgSelectModule,
  AngularCropperjsModule,
  TranslateModule,
  NgbCarouselModule,
  TagInputModule,
];

const PIPES = [
  TimeAgoPipe,
  UserPipe,
  CrFormatPipe,
  ConvertDatePipe,
  TranslatedDescriptionPipe,
  CrValuePipe,
  TrancateTimePipe,
  CrTranslatorPipe,
  LinkPatternPipe,
  BrightnessPipe,
  RolePipe,
  StatusPipe,
];

const SERVICES = [
  UploadFilesService,
  ToastService,
  UserService,
  PublicFilesService,
  ExportService,
  AuthGuard,
  SubscribedEventGuard,
  AuthEventGuard,
  AuthAcpGuard,
  SharedService,
  LanguageService,
];

@NgModule({
  entryComponents: [ENTRY_COMPONENTS],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
  imports: [...MODULES, TranslateModule.forChild(TRANSLATE_LOADER_CONFIGS)],
  exports: [...COMPONENTS, ...MODULES, ...DIRECTIVES, ...PIPES],
  providers: [
    ...SERVICES,
    NgbCarouselConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
