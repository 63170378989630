import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/portal/components/configuration/services/payment.service';

@Component({
  selector: 'app-renew-success-payment',
  templateUrl: './renew-success-payment.component.html',
  styleUrls: ['./renew-success-payment.component.scss'],
})
export class RenewSuccessPaymentComponent implements OnInit {
  paymentRef: any;
  status = '';
  timer = 10;
  redirectTo: string = '/account';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['payment_ref']) {
        this.paymentRef = queryParams['payment_ref'];
        this.paymentService.checkRenewSubscription(this.paymentRef).subscribe(
          (res) => {
            if (res.status == 200) {
              this.updateUserData();
              this.status = 'SUCCESS';
              setInterval(() => {
                this.timer--;
              }, 1000);
              setTimeout(() => {
                if (queryParams['redirect_to']) {
                  this.redirectTo = queryParams['redirect_to'];
                  // window.open(queryParams['redirect_to'], '_self');
                  this.router.navigate([this.redirectTo]);
                } else {
                  this.router.navigate([this.redirectTo]);
                }
              }, 10000);
            }
          },
          (error) => {
            // if (error.status == 404) {
            //   this.status = 'EXPIRED';
            this.router.navigate(['/payment/renew/failed'], {
              queryParams: { payment_ref: this.paymentRef },
            });
            // } else {
            //   this.status = 'FAILED';
            //   this.router.navigate(['/payment/renew/failed'], {
            //     queryParams: { payment_ref: this.paymentRef },
            //   });
            // }
          }
        );
      } else {
        this.router.navigate(['/authentication']);
      }
    });
  }

  getRoute(url): any {
    return url.map((segment) => segment.path).join('/');
  }

  updateUserData() {
    let connectedUser = JSON.parse(localStorage.getItem('me'));
    connectedUser.remainingDays = 366;
    connectedUser.enable = true;
    localStorage.setItem('me', JSON.stringify(connectedUser));
    if (sessionStorage.getItem('enable') == 'false') {
      sessionStorage.setItem('enable', 'true');
    }
  }
}
