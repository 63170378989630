import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationArgs } from 'src/app/shared/models/pagination.args.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public apiUrl = environment.apis.baseUrl;
  paginationArgs: PaginationArgs = { pageNumber: 1, pageSize: 10 };

  constructor(private http: HttpClient) {}

  getAllConnectors(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(this.apiUrl + `/paiement/configurations`, {
      headers,
    });
  }

  getConnectorInformations(connectorCode: String): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(
      this.apiUrl + `/paiement/configuration/${connectorCode}`,
      {
        headers,
      }
    );
  }

  changeConnectorStatus(
    connectorCode: String,
    status: String
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(
      this.apiUrl + `/paiement/configuration/${connectorCode}/${status}`,
      {
        headers,
      }
    );
  }

  updateConditionsConfiguration(
    conditions,
    default_price,
    default_currency
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.put<any>(
      this.apiUrl + '/paiement/conditions/configuration',
      { default_price, default_currency, conditions },
      { headers }
    );
  }

  checkPayment(paymentRef: any): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + `/paiement/konnect/valdiation/${paymentRef}`
    );
  }

  updateConnector(connector_code: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.put<any>(
      this.apiUrl + `/paiement/configuration/${connector_code}`,
      data,
      {
        headers,
      }
    );
  }

  getConditionsConfiguration(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(
      this.apiUrl + '/paiement/conditions/configuration',
      { headers }
    );
  }

  // payment statistics methods
  getTotalRevenu(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(this.apiUrl + '/analysis/revenu/totalRevenu', {
      headers,
    });
  }

  getTotalRevenuPerYear(year: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(`${this.apiUrl}/analysis/revenu/${year}`, {
      headers,
    });
  }

  getTotalRevenuPerYearAndMonth(year: number, month: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(
      `${this.apiUrl}/analysis/revenu/${year}/${month}`,
      {
        headers,
      }
    );
  }

  getSubscriptionsHistoric(filter: any, paginationArgs: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);

    return this.http.post<any>(
      this.apiUrl + '/analysis/revenu/history/subscriptions',
      filter,
      { headers, params }
    );
  }

  getPaymentHistoryByUser(userId): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(
      this.apiUrl + `/payment/subscription/history/${userId}`,
      { headers }
    );
  }

  renewSubscription(redirect?): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    const params = new HttpParams().set('redirect_to', redirect);
    return this.http.get<any>(this.apiUrl + `/paiement/konnect/renew`, {
      params,
      headers,
    });
  }

  checkRenewSubscription(paymentRef): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(
      this.apiUrl + `/paiement/konnect/renew/validation/${paymentRef}`,
      {
        headers,
      }
    );
  }
}
