import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from 'src/app/shared/config/translate.config';

@Component({
  selector: 'app-header-language-portal',
  templateUrl: './header-language-portal.component.html',
  styleUrls: ['./header-language-portal.component.scss']
})
export class HeaderLanguagePortalComponent implements OnInit {
  public languages = SUPPORTED_LANGUAGES;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

  public toggleLang(lang: any) {
    localStorage.setItem('lang', lang.code);
    this.translate.use(lang.code);
  }
}
