import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpCenterService {
  public apiUrl = environment.apis.baseUrl;

  constructor(private http: HttpClient) { }

  sendMessageSupport(data) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    return this.http.post<any>(this.apiUrl + '/reclamation/create', data, { headers })
  }
}
