import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-viewer',
  templateUrl: './user-viewer.component.html',
  styleUrls: ['./user-viewer.component.scss']
})
export class UserViewerComponent implements OnInit {

  @Input() userId;
  @Input() appointment_pending;
  @Input() appointment_accepted;
  @Input() allow_appointments;
  
  user;
  loading: boolean = true;
  connectedUser: any;

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService
  ) { }

  ngOnInit(): void {

    this.connectedUser = JSON.parse(localStorage.getItem('me'))
    this.userService.getUserByID(this.userId).subscribe(res => {
      this.user = res.Data[0];
      this.loading = false;
    })
  }

  getClickableLink(link){
    return link.startsWith("http://") || link.startsWith("https://") ? link : `//${link}`;
  };

  getAppointment(){
    this.activeModal.close(true)
  }

}
