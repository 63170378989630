import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { LayoutsService } from '../services/layouts.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  connectedUser: User;
  selectedEvent: FormGroup;
  events: Observable<any>;
  placeholder: string;
  unseenMessageCount: number;
  interval: NodeJS.Timeout;

  constructor(
    private router: Router, 
    private userService: UserService,
    private layoutsService: LayoutsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.notifMsg();
    this.initForm();
    this.connectedUser = JSON.parse(localStorage.getItem('me'));
    this.getEventsByUser();

    this.userService.connectedUser$.subscribe(
      status => {
        if (Object.keys(status).length) { 
          setTimeout(() => {
            if(localStorage.getItem('me')){
              this.connectedUser = JSON.parse(localStorage.getItem('me')) ;
            }
          }, 100);
        }
      }
    );
  }

  notifMsg(){
    this.layoutsService.unseenMessageCount().subscribe(count => {
      this.unseenMessageCount = count.numberMessages
    });

    this.interval = setInterval(() => {
      this.layoutsService.unseenMessageCount().subscribe(count => {
        this.unseenMessageCount = count.numberMessages
      });
    }, 20000)
  }

  logout(){
    localStorage.removeItem('me');
    sessionStorage.removeItem('token');
    this.router.navigateByUrl('/authentication');
  }

  initForm(){
    this.selectedEvent = this.formBuilder.group({
      id: new FormControl(null)
    })
  }

  getEventsByUser() {
    this.layoutsService.getEvents().subscribe(res => {
      this.events = of(res['Data']);
    })
  }

  navigateToEvent(event) {
    this.router.navigateByUrl('/room/event/' + event.id + '/details');
  }

  settingsProfile(){
    this.selectedEvent.get('id').setValue(null)
    this.router.navigateByUrl('/portal/settings');
  }

  toggleMenu(event) {
    if (event.target.closest('.header-content-wrapper').classList.contains('open-menu')) {
      event.target.closest('.header-content-wrapper').classList.remove('open-menu');
    } else {
      event.target.closest('.header-content-wrapper').classList.add('open-menu');
    }
  }
  
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }


}
