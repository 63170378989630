import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss']
})
export class MediaViewerComponent implements AfterViewInit {

  @Input() mediaList: any = [];
  @Input() index: number = 0;

  thumbnail: any;

  constructor(public activeModal: NgbActiveModal) { }


  ngAfterViewInit(): void {
    this.thumbnail = document.querySelector('.thumbnail');
    this.selectMedia(this.index)
  } 


  selectMedia(index) {
    this.index = index;
    this.thumbnail.style.transform = 'translateX(' + (-100 * index) + 'px)';
    const thumbnailItem = document.querySelector('.thumbnail-item-' + index);
    document.querySelectorAll('.thumbnail-item').forEach(element => {
      element.classList.remove('active');
    });
    setTimeout(() => {
      thumbnailItem.classList.add('active');
    }, 100);
  }

  goToMedia(index) {
    this.index = index;
    this.selectMedia(this.index);
    this.thumbnail.style.transitionDuration = "0.5s";
  }

  prevMedia() {
    if (this.index > 0) {
      this.thumbnail.style.transitionDuration = "0.5s";
      this.index--;
      this.selectMedia(this.index);
    }
  }

  nextMedia() {
    if (this.index < (this.mediaList.length - 1)) {
      this.thumbnail.style.transitionDuration = "0.5s";
      this.index++;
      this.selectMedia(this.index);
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  ouvrirLienExterne(lien) {
    let newUrl

    if (!lien.startsWith("http://") && !lien.startsWith("https://")) {
      newUrl = "http://" + lien;
    }
    return newUrl
  }

}
