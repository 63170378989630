import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../services/user.service';
import moment from 'moment';
import { languageByCode } from '../../config/translate.config';

@Component({
  selector: 'app-event-card-admin',
  templateUrl: './event-card-admin.component.html',
  styleUrls: ['./event-card-admin.component.scss']
})
export class EventCardAdminComponent implements OnInit {
  @Input() event;
  @Input() adminView;
  @Input() comingPage;
  @Input() source;
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() updateStatus = new EventEmitter();

  constructor(private userService : UserService) { }

  ngOnInit(): void {
  }

  updateEvent(){
    this.update.emit(this.event)
  }

  deleteEvent(){
    this.delete.emit(this.event.id)
  }

  changeStatus(status){
    this.updateStatus.emit({id: this.event.id, status:status})
  }

  convertDate(date){
    let userLang: string = localStorage.getItem('lang');
    if(date){
      return moment(date).locale(userLang).format('MMMM Do, YYYY');
    } else{
      return '';
    }
  }

}
