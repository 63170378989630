import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { PaymentService } from 'src/app/portal/components/configuration/services/payment.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-warning-subscription-bar',
  templateUrl: './warning-subscription-bar.component.html',
  styleUrls: ['./warning-subscription-bar.component.scss'],
})
export class WarningSubscriptionBarComponent implements OnInit {
  connectedUser: User;
  currentRoute: string;
  show: boolean = true;
  timer: number = 3;
  free: boolean = false;
  valide: boolean = false;
  loading: boolean = false;

  routesArray = [
    '/payment/success',
    '/payment/failed',
    '/payment/renew/success',
    '/payment/renew/failed',
  ];
  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    if (
      this.router.url.includes(this.routesArray[0]) ||
      this.router.url.includes(this.routesArray[1]) ||
      this.router.url.includes(this.routesArray[2]) ||
      this.router.url.includes(this.routesArray[3])
    ) {
      this.show = false;
    }
  }

  getUserInfo() {
    this.connectedUser = JSON.parse(localStorage.getItem('me'));
  }

  setUserInfo() {
    localStorage.setItem('me', JSON.stringify(this.connectedUser));
  }

  GoToPay() {
    this.loading = true;
    this.paymentService.renewSubscription(this.router.url).subscribe(
      (res) => {
        if (res.action == 'FREE_RENEW_SUCCESS') {
          this.connectedUser.remainingDays += 365;
          this.setUserInfo();
          const interval = setInterval(() => {
            this.timer--;
            if (this.timer === 0) {
              this.loading = false;
              this.valide = true;
              clearInterval(interval);
            }
          }, 1000);
          setTimeout(() => {
            this.show = false;
          }, 6000);

          // const interval = setInterval(() => {
          //   this.timer--;
          //   if (this.timer === 0) {
          //     this.show = false;
          //     clearInterval(interval);
          //   }
          // }, 1000);
        } else {
          window.open(res.paymentInformation.payUrl, '_self');
        }
      },
      (error) => {
        this.loading = false;
        this.toastService.error('Contact the administrator.');
      }
    );
  }
}
