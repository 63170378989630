import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/portal/components/configuration/services/payment.service';

@Component({
  selector: 'app-renew-failed-payment',
  templateUrl: './renew-failed-payment.component.html',
  styleUrls: ['./renew-failed-payment.component.scss'],
})
export class RenewFailedPaymentComponent implements OnInit {
  paymentRef: any;
  status: string = 'EXPIRED';
  redirectTo = '/account';

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['payment_ref']) {
        this.paymentRef = queryParams['payment_ref'];
        this.paymentService.checkRenewSubscription(this.paymentRef).subscribe(
          (res) => {
            this.router.navigate(['/payment/renew/success'], {
              queryParams: { payment_ref: this.paymentRef },
            });
          },
          (error) => {
            if (queryParams['redirect_to']) {
              this.redirectTo = queryParams['redirect_to'];
              this.status = 'FAILED';
            }
          }
        );
      } else {
        this.router.navigate(['/authentication']);
      }
    });
  }
}
