<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{"TRANSLATE_LABEL"| translate}}</h4>
    <span class="close" (click)="activeModal.close(null)">
        <svg class="olymp-close-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use></svg>
    </span>
</div>

<div class="modal-body">
    <form [formGroup]="labelForm">
        <div formArrayName="labels">
          <ng-container *ngFor="let item of labels.controls;let index = index">
            <div class="form-group label-floating form-group-translatebal" [ngClass]="{'is-empty': !item.get('value').value }">
                <label for="search-cr" class="control-label">Label</label>
                <input *ngIf="InputType=='text'" type="text" class="form-control" id="search-cr" [formControl]="item.get('value')" 
                    [ngClass]="{
                        'is-invalid': submitted && (item.get('value')?.status == 'INVALID')
                    }"
                    (focus)="focusedField($event)" (blur)="focusedField($event)" />

                <textarea *ngIf="InputType=='textarea'" class="form-control" id="search-cr" [formControl]="item.get('value')" 
                    [ngClass]="{
                        'is-invalid': submitted && item.get('value')?.status === 'INVALID'
                    }"
                    (focus)="focusedField($event)" (blur)="focusedField($event)">
                </textarea>
                <span class="search-icon">
                    <img class="flag-input" [src]="'/assets/svg-icons/'+item.get('language').value+'.svg'" [alt]="'da'" />
                </span>
            </div>
            <span class="error-msg text-danger" *ngIf="submitted && item.get('value')?.errors?.currentLangValue">{{'ERROR_REQUIRED' | translate}}</span>

          </ng-container>
        </div>
      </form>
</div>

<div class="modal-footer">
    <button class="btn btn-primary btn-lg full-width mb-0" (click)="saveForm()">
        {{ 'BUTTON.SAVE' | translate }}
    </button>
</div>