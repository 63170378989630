import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brightness'
})
export class BrightnessPipe implements PipeTransform {
  transform(imageUrl: string): boolean {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    const image: HTMLImageElement = new Image();

    image.src = imageUrl;

    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);

    // Analyze image brightness
    const imageData: ImageData = context.getImageData(0, 0, image.width, image.height);
    const data: Uint8ClampedArray = imageData.data;
    let brightnessSum = 0;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const brightness = (r + g + b) / 3; // Calculate average brightness
      brightnessSum += brightness;
    }

    const averageBrightness = brightnessSum / (data.length / 4);
    const isDark = averageBrightness < 128; // Define a threshold for darkness

    return isDark;
  }
}
