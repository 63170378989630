import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { HeaderLanguagePortalComponent } from './components/header-language-portal/header-language-portal.component';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { HelpCenterModule } from '../help-center/help-center.module';

@NgModule({
  declarations: [HeaderComponent, HeaderLanguagePortalComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SharedModule,
    HelpCenterModule,
  ],
  exports: [HeaderComponent],
  providers: [NgbDropdown],
})
export class HeaderModule {}
