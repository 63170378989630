import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment-routing.module';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { FailedPaymentComponent } from './failed-payment/failed-payment.component';
import { RenewSuccessPaymentComponent } from './renew-success-payment/renew-success-payment.component';
import { RenewFailedPaymentComponent } from './renew-failed-payment/renew-failed-payment.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { HeaderModule } from '../layouts/header/header.module';

@NgModule({
  declarations: [
    SuccessPaymentComponent,
    FailedPaymentComponent,
    RenewSuccessPaymentComponent,
    RenewFailedPaymentComponent,
  ],
  imports: [CommonModule, PaymentRoutingModule, HeaderModule],
})
export class PaymentModule {}
