<div
  class="d-flex flex-column justify-content-center align-items-center vh-100 w-100 text-center"
>
  <div
    *ngIf="status != 'EXPIRED'"
    class="d-flex flex-column justify-content-center align-items-center text-center bg-white card-error-container p-5"
  >
    <img src="assets/img/gif/error.gif" width="130px" alt="" />
    <h2 class="error-text mt-4">Payment Failed</h2>
    <p class="px-5">
      You need to use valide information to confirm the payment.
    </p>
    <button class="btn btn-error" [routerLink]="'/authentication/sign-up'">
      Back to Sign Up
    </button>
  </div>

  <div
    *ngIf="status == 'EXPIRED'"
    class="d-flex flex-column justify-content-center align-items-center text-center bg-white card-error-container p-5"
  >
    <img src="assets/img/gif/error.gif" width="130px" alt="" />
    <h2 class="error-text mt-4">Payment Expired</h2>
    <p class="px-5">Your link of payment has been expired, try again!</p>
    <button class="btn btn-error" [routerLink]="'/authentication/sign-up'">
      Back to Sign Up
    </button>
  </div>
</div>
