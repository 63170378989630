import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public apiUrl = environment.apis.baseUrl;

  constructor(public http: HttpClient) { }

  searchEvent(data) {
    // debounce
    return timer(1000)
      .pipe(
        switchMap(() => {
          // Check if username is available
          return this.http.post<any>(`${this.apiUrl}/event/exist/name`, data)
        })
      );
  }

  checkExistenceEvent(id): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      let data = {
        id: id,
        name: control.value
      }
      return this.searchEvent(data)
        .pipe(
          map(res => {
            // if username is already taken
            if (res.exist_name) {
              return { 'exist': true };
            }
          })
        );
    };
  }
}
