import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/portal/components/configuration/services/payment.service';

@Component({
  selector: 'app-failed-payment',
  templateUrl: './failed-payment.component.html',
  styleUrls: ['./failed-payment.component.scss'],
})
export class FailedPaymentComponent implements OnInit {
  paymentRef: any;
  status: string = 'FAILED';

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['payment_ref']) {
        this.paymentRef = queryParams['payment_ref'];
        this.paymentService.checkPayment(this.paymentRef).subscribe(
          (res) => {
            if (res.status == 200) {
              this.status = 'SUCCESS';
            } else {
              this.status = 'PENDING';
            }
          },
          (error) => {
            if (error.status == 404) {
              this.status = 'EXPIRED';
            } else {
              this.status = 'FAILED';
            }
          }
        );
      } else {
        this.router.navigate(['/authentication']);
      }
    });
  }
}
