import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { refCount, publishReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public apiUrl = environment.apis.baseUrl;
  private connectedUserSource = new BehaviorSubject<any>({});
  public connectedUser$ = this.connectedUserSource.asObservable().pipe(publishReplay(1), refCount());;

  constructor(private http: HttpClient) { }

  filterUsers(filterObject): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    let params = new HttpParams();
    Object.keys(filterObject).forEach(item => {
      params = params.set(item, `${filterObject[item]}`);
    })

    return this.http.get(this.apiUrl + '/user/profile', { headers, params })
  }

  getUserByID(id): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });

    return this.http.get(this.apiUrl + '/user/' + id, { headers })
  }

  getCompanyByID(id, attributs = {}): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });

    return this.http.post(this.apiUrl + `/entreprise/${id}`, attributs, { headers })
  }

  getCompanyMedia(id, mediaType): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });

    return this.http.post(this.apiUrl + `/${id}/entreprise/medias`,mediaType, { headers })
  }

  getupdatedUser(data = JSON.parse(localStorage.getItem('me'))): Observable<any> {
    let userStatus = data;
    this.connectedUserSource.next(userStatus);
    return this.connectedUser$;
  }

  updateUser(data) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    return this.http.put<any>(this.apiUrl + '/user/edit', data, { headers })
  }

  updateUserCompany(data) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    return this.http.put<any>(this.apiUrl + '/me/entreprise', data, { headers })
  }

  changePassword(data) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    return this.http.post<any>(this.apiUrl + '/user/changepassword', data, { headers })
  }

  getUserAlbum(mediaType): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    return this.http.post(this.apiUrl + '/me/entreprise/medias',mediaType, { headers })
  }

  mgtAlbum(data): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    return this.http.post<any>(this.apiUrl + '/me/entreprise/upload/medias', data, { headers })
  }

  getUsers(filters, paginationArgs): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);

    return this.http.post(this.apiUrl + '/entreprise/filter', filters, { headers, params })
  }

  getCRvalues(type, paginationArgs, filter = {}): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });
    
    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);

    return this.http.post<any>(this.apiUrl + `/${type}/`, filter, { headers, params })
  }

  getEventByUser(): Observable<any>{
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    });

    return this.http.get(this.apiUrl + '/my/events/subscription', { headers })
  }

}