import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicFilesService {
  private url = environment.apis.document

  constructor(private http: HttpClient) { }

  uploadFile(file: File, progress: boolean = false): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('mimeType', file.type);

    if (progress) {
      return this.http.post(this.url + 'wp-json/acp/v1/file/upload', formData, { reportProgress: true, observe: 'events' }).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return Math.round(100 * event.loaded / event.total);
            case HttpEventType.Response:
              return [
                {
                  id: event.body['data'].url,
                  fileName: event.body['data'].filename,
                  fileType: event.body['data'].mime,
                  upload_file: event.body["data"].url.substring(event.body["data"].url.indexOf('/uploads/')+9),
                }
              ];
          }
        })
      )
    }
  }
}
