<div class="ui-block">

    <!-- Post -->
    
    <article class="hentry blog-post">
    
        <div class="post-thumb">
            <a [routerLink]="adminView ? '/portal/events/' + event.id : source ? '/account/events/' + event.id : '/events/' + event.id" [state]="{ page: comingPage }">
                <img [src]="event.imageCover?event.imageCover:'assets/img/post1.jpg'" alt="photo">
            </a>
        </div>
    
        <div class="post-content">
            <div class="super-header mb-3" *ngIf="adminView" >
                <a [class]="'post-category bg-blue-light mb-0 status-'+event.status">{{ event.status == 'HIDE' ? ('MASKED' | translate) : ('PUBLISHED' | translate) }}</a>
                <a *ngIf="event.inProgress == '0'" class="post-category bg-blue-light closed-event mb-0 mr-auto">{{ 'EVENT_INACTIVE' | translate }}</a>
                <div class="more">
                    <svg class="olymp-three-dots-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use></svg>
                    <ul class="more-dropdown">
                        <li>
                            <a class="cursor-pointer" (click)="deleteEvent()">{{'DELETE' | translate}}</a>
                        </li>
                        <li *ngIf="event.status == 'HIDE'">
                            <a class="cursor-pointer" (click)="changeStatus('SHOW')">{{'PUBLISH' | translate}}</a>
                        </li>
                        <li *ngIf="event.status == 'SHOW'">
                            <a class="cursor-pointer" (click)="changeStatus('HIDE')">{{'HIDE' | translate}}</a>
                        </li>
                    </ul>
                </div>

            </div>
            <a [routerLink]="adminView ? '/portal/events/' + event.id : source ? '/account/events/' + event.id : '/events/' + event.id" [state]="{ page: comingPage }" class="h4 post-title" [title]="event.name">{{ (event.name?.length>30)? (event.name | slice:0:30)+'...':(event.name) }}</a>
            <!-- <p>Here’s a photo from last month’s photoshoot. We got really awesome shots for the new catalog.</p> -->
            
                <div class="author-date">
                    <ng-container *ngIf="adminView">
                        {{'BY' | translate}} <a class="h6 post__author-name fn" [ngbTooltip]="event.creator.first_name + ' ' + event.creator.last_name" tooltipClass="tooltipClass">{{event.creator.first_name + ' ' + event.creator.last_name}}</a>
                    </ng-container>
                    
                    <div class="post__date">
                        <time class="published" datetime="2017-03-24T18:18">
                            <i class="far fa-clock mr-1"></i> {{(event.createdAt) | timeAgo}}
                        </time>
                    </div>
                </div>
                
            
            <div class="post-additional-info inline-items">
                <div class="more-info">
                    <span class="date"><i class="far fa-calendar-alt"></i>{{convertDate(event?.startingDate)}}</span>
                    <span *ngIf="event?.location" [ngbTooltip]="event?.location" class="location"><i class="fas fa-map-marker-alt"></i>{{event?.location}}</span>
                </div>
            </div>
        </div>
    
    </article>
    
    <!-- ... end Post -->
</div>