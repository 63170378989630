import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpFormComponent } from './components/help-form/help-form.component';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  sendMessageModal() {
    const modalRef = this.modalService.open(
      HelpFormComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
  }

}
