<article class="hentry blog-post">
    <div class="post-thumb">
        <a (click)="viewDetails(company)">
            <img [src]="company?.companyLogo ? company.companyLogo :'/assets/img/logo-placeholder.png'" alt="photo">
        </a>
    </div>

    <div class="post-content">
        <div class="post-title">
            <a (click)="viewDetails(company)" class="h4 mb-0">{{ company?.society }}</a>
            <span><i class="fas fa-globe-africa"></i> {{company?.country | crValue:'country'}}</span>
        </div>
        
        <!--<div class="description">
            <p class="mb-0">
                {{company?.companyDescription}}
            </p>
        </div>-->
    </div>

</article>
