import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { timer, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  public apiUrl = environment.apis.baseUrl;

  constructor(private http: HttpClient) { }

  addSubscription(data) {
    return this.http.post<any>(this.apiUrl + '/subscription/event/create', data)
  }

  searchSubscription(data) {
    // debounce
    return timer(1000)
      .pipe(
        switchMap(() => {
          // Check if username is available
          return this.http.post<any>(`${this.apiUrl}/subscription/event/exist`, data)
        })
      );
  }

  checkExistenceSubscription(id): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      let data = {
        eventId: id,
        email: control.value
      }
      return this.searchSubscription(data)
        .pipe(
          map(res => {
            // if username is already taken
            if (res.exist_subscription) {
              return { 'exist': true };
            }
          })
        );
    };
  }
}
