<div class="ui-block card-loading">
    <div class="image"></div>
    <div class="content" [class.not-admin]="!adminView">
      <div *ngIf="adminView" class="tag"></div>
      <h1></h1>
    </div>

    <div class="more-info">
      <div class="date"></div> <div class="location"></div>
    </div>
    <div class="card-info">
        <div class="info-content">
            <div></div>
        </div>
    </div>
  </div>