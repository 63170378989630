import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-label-traduction',
  templateUrl: './label-traduction.component.html',
  styleUrls: ['./label-traduction.component.scss']
})
export class LabelTraductionComponent implements OnInit {

  public labelForm: FormGroup;
  @Input() values;
  @Input() InputType = 'text';
  public languagesValues: any[];
  submitted: boolean ;

  constructor(public activeModal: NgbActiveModal) { }

  public ngOnInit() {
    this.labelForm = new FormGroup({
      labels: new FormArray([]),
    });

    for (let j = 0; j < this.values.length; j++) {
      this.labels.push(
        new FormGroup({
          value: new FormControl(this.values[j].value , this.currentLangValidator(this.values[j].language)),
          language: new FormControl(this.values[j].language),
        })
      );
    }
  }

  currentLangValidator(lang: string): ValidatorFn {
    return (control: FormControl) => {
      if(lang == localStorage.getItem('lang')){
        return control.value ? null : { currentLangValue: true };
      }
      return null
    };
  }

  get labels(): FormArray {
    return this.labelForm.get('labels') as FormArray;
  }

  focusedField(event) {
    if (event.type == 'focus') {
      event.target.closest('.label-floating').classList.add('is-focused');
    } else {
      event.target.closest('.label-floating').classList.remove('is-focused');
    }
  }

  public capitalize = (s: string) => {
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  public saveForm() {
    this.submitted = true;
    if (this.labelForm.valid) {
      this.activeModal.close(this.labelForm.get('labels').value);
    }
  }
}
