import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Pipe({
  name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(value: any, pattern: string = 'Do MMMM YYYY'): string {
    let userLang: string = localStorage.getItem('lang') ? localStorage.getItem('lang') : this.translate.getDefaultLang();
    return moment(value).locale(userLang).format(pattern);
  }

}
