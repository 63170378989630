<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'IMAGE_CROPPING' | translate}}</h4>
    <span class="close" (click)="activeModal.close('Cross click')">
        <svg class="olymp-close-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use></svg>
    </span>
</div>
<div class="modal-body">


    <div class="row">
        <div class="col-lg-6 order-2 order-lg-1">
            <image-cropper [imageChangedEvent]="srcFile" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
                [resizeToWidth]="resizeToWidth" format="png" (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 position-relative">
            <div class="result-cropping">
                <div class="progress-bar" *ngIf="uploadForm?.getRawValue().progress && submitCropp">
                    <div class="progress-bar__bg bg-light-3"></div>
                    <div class="progress-bar__bar bg-purple-1" [style.width.%]="uploadForm?.getRawValue().progress">
                      <span>{{uploadForm?.getRawValue().progress}}%</span>
                    </div>
                  </div>
                <img [src]="croppedImage" />
            </div>
            
        </div>
    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-primary btn-lg" [disabled]="uploadForm.status == 'INVALID'" (click)="saveFile()">Save</button>
</div>

