import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[infiniteScroll]'
})
export class InfiniteScrollDirective {

    @Output() public loadmore: any = new EventEmitter();

    constructor(private element: ElementRef) { }

    @HostListener('scroll', ['$event'])
    public onScroll(event) {
        const tracker = event.target;
        const limit = tracker.scrollHeight - tracker.clientHeight;
        if (Math.round(event.target.scrollTop) >= limit) {
            this.loadmore.emit();
        }
    }
}
