import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  languageByCode,
  TRANSLATE_LOADER_CONFIGS,
} from './shared/config/translate.config';
import { LanguageService } from './shared/services/language.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentModule } from './payment/payment.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    PaymentModule,
    TranslateModule.forRoot(TRANSLATE_LOADER_CONFIGS),
  ],
  providers: [
    LanguageService,
    {
      provide: LOCALE_ID,
      useValue: languageByCode(localStorage.getItem('lang')),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
