import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})
export class AreaChartComponent implements OnInit, OnChanges {
  title = 'ng-chart';
  chart: any = [];
  @Input() keys;
  @Input() values;
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.initChart();
    }, 1000);
  }
  initChart() {
    this.chart = new Chart('AreaChart', {
      type: 'line',
      data: {
        labels: this.keys, // to change
        datasets: [
          {
            label: 'Evolution',
            data: this.values, // to change
            borderColor: '#38a9ff', // Set your desired line colors here
            backgroundColor: '#38a9ff', // Set your desired fill colors here
            fill: false,
            tension: 0.1,
          },
        ],
      },
      options: {
        title: {
          display: false,
        },
      },
    });
  }
  ngOnChanges(changes: SimpleChanges) {}
}
