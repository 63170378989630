<span class="close" (click)="activeModal.close('Cross click')">
    <svg class="olymp-close-icon"><use xlink:href="/assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use></svg>
</span>

<div class="modal-body p-0">
    <div class="header-profile">
        <h2 [ngClass]="{'load-title-h2':loading}" class="mb-0 pb-2">{{user?.first_name}} {{user?.last_name}}</h2>
        <h4 [ngClass]="{'load-title-h4':loading}" *ngIf="user?.society || loading" class="mb-0">{{user?.society}}</h4>
    </div>
    <div class="pic">
        <span [ngClass]="{'load-pic':loading}"><img [src]="user?.profilePicture"/></span>
    </div>
    <div class="body-profile">
        <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="user?.participantFunction || loading">
            <h6 *ngIf="user?.participantFunction">{{'POSITION' | translate}} : </h6>
            {{user?.participantFunction}}
        </div>

        <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="user?.country || loading">
            <h6 *ngIf="user?.country">{{'COUNTRY' | translate}} : </h6>
            <ng-container *ngIf="user?.country">{{user?.country | crValue:'country'}}</ng-container>
        </div>

        <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="user?.sector || loading">
            <h6 *ngIf="user?.sector">{{'SECTOR' | translate}} : </h6>
            <ng-container *ngIf="user?.sector">{{user?.sector | crValue:'sector'}}</ng-container>
        </div>

        <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="user?.phone || loading">
            <h6 *ngIf="user?.phone">Mobile : </h6>
            {{user?.phone}}
        </div>

        <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="user?.user_email || loading">
            <h6 *ngIf="user?.phone">E-mail : </h6>
            {{user?.user_email}}
        </div>

        <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="user?.website || loading">
            <h6 *ngIf="user?.website">{{'WEB_SITE' | translate}} : </h6>
            <a *ngIf="user?.website" [href]="getClickableLink(user?.website)" target="_blank">{{user?.website}}</a>
        </div>

        <div [ngClass]="{'load-title-p':loading}" class="section-profile" *ngIf="user?.productServices || loading">
            <h6 *ngIf="user?.productServices">{{'SERVICE_PRODUCT' | translate}} : </h6>
            <p>{{user?.productServices}}</p>
        </div>

        <button *ngIf="user && appointment_accepted == '0' && appointment_pending == '0' && allow_appointments && connectedUser['ID'] != user.userId" class="btn btn-primary btn-md full-width" (click)="getAppointment()">
            {{'APPOINTMENT' | translate}}
        </button>
    </div>
</div>