import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { MediaViewerComponent } from '../media-viewer/media-viewer.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-viewer',
  templateUrl: './company-viewer.component.html',
  styleUrls: ['./company-viewer.component.scss'],
})
export class CompanyViewerComponent implements OnInit {
  @Input() company;
  @Input() veiwType = 'user';
  loading: boolean = false;
  attributs = {
    attributs: [
      'userId',
      'user_email',
      'first_name',
      'last_name',
      'gender',
      'role',
      'profilePicture',
      'country',
      'phone',
      'participantFunction',
      'sector',
      'society',
      'productServices',
      'language',
      'website',
      'establishmentYear',
      'companySize',
      'companyLogo',
      'companyAdress',
      'services',
      'products',
      'tags',
      'companyDescription',
    ],
  };
  userInfo: any;
  userMedia: any;
  userDocument: any;
  connectedUser: any;
  activeTab: string = 'general';

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.connectedUser = JSON.parse(localStorage.getItem('me'));
    this.userService
      .getCompanyByID(this.company.userId, this.attributs)
      .subscribe((userInfo) => {
        this.userInfo = Object.assign(
          {},
          userInfo.Data.personelInformation[0],
          {
            products: userInfo.Data['products'],
            services: userInfo.Data['services'],
          }
        );
      });

    this.userService
      .getCompanyMedia(this.company.userId, { mediaType: 'IMAGES' })
      .subscribe((userMedia) => {
        this.userMedia = userMedia.Data;
      });

    this.userService
      .getCompanyMedia(this.company.userId, { mediaType: 'OTHERS' })
      .subscribe((userDocument) => {
        this.userDocument = userDocument.Data;
      });
  }

  getClickableLink(link) {
    return link.startsWith('http://') || link.startsWith('https://')
      ? link
      : `//${link}`;
  }

  veiwMedia(data, index) {
    const modalRef = this.modalService.open(MediaViewerComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    });
    modalRef.componentInstance.mediaList = data.map((item) => {
      return { image: item.content };
    });
    modalRef.componentInstance.index = index;
    modalRef.result.then((result) => {
      if (result && result != 'Cross click') {
      }
    });
  }

  chatRoom(id) {
    this.activeModal.close();
    this.router.navigate(['/account/chat'], { queryParams: { ref: id } });
  }

  editProfile() {
    this.activeModal.close();
    this.router.navigate(['/account/settings/personal-informations']);
  }

  switchTab(tab) {
    this.activeTab = tab;
  }
}
