import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CropperComponent } from 'angular-cropperjs';
import {AbstractControl, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {map} from "rxjs/operators";
import {of} from "rxjs";
import { PublicFilesService } from '../../services/public-files.service';
import { UploadFilesService } from '../../services/upload-files.service';

@Component({
  selector: 'app-img-cropper',
  templateUrl: './img-cropper.component.html',
  styleUrls: ['./img-cropper.component.scss']
})
export class ImgCropperComponent implements OnInit {
  imgFileId: string;
  imgUrl: string;
  imgFile: File;
  config = {
    aspectRatio: 4 / 3,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: true,
    scalable: true,
    zoomable: true,
    viewMode: 0,
    checkImageOrigin: true,
    cropmove: this.cropMoved.bind(this),
    crop: this.cropMoved.bind(this),
    checkCrossOrigin: true
  };
  @Input() public ratio?: any;
  @Input() public srcFile;
  @Input() public fileName: string;
  @Input() isPublic: boolean = false;
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  public uploadForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private uploadFilesService: UploadFilesService,
    private publicFilesService: PublicFilesService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.ratio) {
      this.config.aspectRatio = this.ratio;
    }
    this.initForm();
  }

  cropMoved(data) {    
    this.imgUrl = data.target.cropper.getCroppedCanvas().toDataURL();
    this.imgFile = this.dataURLtoFile(this.imgUrl, this.fileName);
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl?.split(',');
    let mimeStr = arr[0]?.match(/:(.*?);/);
    const mime = mimeStr ? mimeStr[1] : '';
    const bstr = atob(arr[1]);
    let n = bstr?.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  private initForm() {
    return (this.uploadForm = this.formBuilder.group({
      progress: new FormControl({ value: 0, disabled: false })
    }))
  }

  validateFile(control: AbstractControl) {
    if (control.value && control.value instanceof File) {
      if (this.isPublic) {
        return this.publicFilesService.uploadFile(control.value, true)
          .pipe(
            map(res => {
              if (res?.length && res[0]?.id) {
                control.setValue({source_url: res[0]?.id , upload_file: res[0]?.upload_file});
                control?.parent.get('progress')?.setValue(null);
                this.activeModal.close({ imgUrl: this.imgUrl, MediaMetadata: this.uploadForm.value.media });
                return null;
              } else {
                control?.parent?.get('progress')?.setValue(res ? res : 0);
                return { uploading: true };
              }
            })
          );
      } else {
        return this.uploadFilesService.uploadFile(control.value, true)
          .pipe(
            map(res => {
              if (res?.length && res[0]?.id) {
                control.setValue({source_url: res[0]?.id , upload_file: res[0]?.upload_file});
                control?.parent.get('progress')?.setValue(null);
                this.activeModal.close({ imgUrl: this.imgUrl, MediaMetadata: this.uploadForm.value.media });
                return null;
              } else {
                control?.parent?.get('progress')?.setValue(res ? res : 0);
                return { uploading: true };
              }
            })
          );
      }

    }
    return of(null);
  }


  public saveFile() {
    if (this.imgUrl) {
      this.uploadForm = this.formBuilder.group({
        media: new FormControl(this.imgFile, [], this.validateFile.bind(this)),
        progress: new FormControl({ value: 0, disabled: false })
      })
    }

  }
}
