import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AcpAccountService } from 'src/app/acp-account/services/acp-account.service';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
  @Input() news;

  constructor(public activeModal: NgbActiveModal, private acpAccountService:AcpAccountService) { }

  ngOnInit(): void {
    this.acpAccountService.getNewsById(this.news.id).subscribe(res=>{
      this.news=res.Data;
    })
  }

}
