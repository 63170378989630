import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-thumbnails-carousel',
  templateUrl: './thumbnails-carousel.component.html',
  styleUrls: ['./thumbnails-carousel.component.scss']
})
export class ThumbnailsCarouselComponent implements OnInit {
  @Input() albums;

  public currentSlide = 'slideId_0';
  public activeSliderId = 'slideId_0';
  @ViewChild('carousel') carousel: NgbCarousel;
  
  constructor() {

  }

  ngOnInit(): void {
  }

  onSlide(event) {
    this.activeSliderId = event.current;
    this.currentSlide = event.current;
    this.carousel.cycle()
  }

  cycleToSlide(index: number) {
    this.activeSliderId = 'slideId_' + index;
    this.currentSlide = this.activeSliderId;
  }
}
