<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Inscription</h4>
  <span class="close" (click)="activeModal.close('Cross click')">
    <svg class="olymp-close-icon">
      <use
        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"
      ></use>
    </svg>
  </span>
</div>
<div class="modal-body">
  <div class="mb-4">(*) {{ "REQUIRED_FIELD" | translate }}</div>

  <form [formGroup]="subscriptionForm">
    <div class="row">
      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('last_name').value }"
        >
          <label class="control-label"
            >{{ "FIRST_NAME" | translate }}
            <span class="required">*</span></label
          >
          <input
            class="form-control"
            placeholder=""
            formControlName="last_name"
            type="text"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited &&
                subscriptionForm.get('last_name')?.status === 'VALID',
              'is-invalid':
                submited &&
                subscriptionForm.get('last_name')?.status === 'INVALID'
            }"
          />

          <span
            class="error-msg text-danger"
            *ngIf="
              submited && subscriptionForm.get('last_name')?.errors?.required
            "
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('first_name').value }"
        >
          <label class="control-label"
            >{{ "LAST_NAME" | translate }}
            <span class="required">*</span></label
          >
          <input
            class="form-control"
            placeholder=""
            type="text"
            formControlName="first_name"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited &&
                subscriptionForm.get('first_name')?.status === 'VALID',
              'is-invalid':
                submited &&
                subscriptionForm.get('first_name')?.status === 'INVALID'
            }"
          />

          <span
            class="error-msg text-danger"
            *ngIf="
              submited && subscriptionForm.get('first_name')?.errors?.required
            "
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('email').value }"
        >
          <label class="control-label"
            >E-mail <span class="required">*</span></label
          >
          <img
            class="loading-buffering"
            *ngIf="subscriptionForm.get('email').status == 'PENDING'"
            src="/assets/img/gif/loading-buffering.gif"
          />
          <input
            class="form-control"
            placeholder=""
            type="email"
            formControlName="email"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited && subscriptionForm.get('email')?.status === 'VALID',
              'is-invalid':
                (submited &&
                  subscriptionForm.get('email')?.status === 'INVALID') ||
                subscriptionForm.get('email')?.errors?.exist
            }"
          />

          <span
            class="error-msg text-danger"
            *ngIf="submited && subscriptionForm.get('email')?.errors?.required"
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
          <span
            class="error-msg text-danger"
            *ngIf="submited && subscriptionForm.get('email')?.errors?.pattern"
            >Invalid E-mail</span
          >
          <span
            class="error-msg text-danger"
            *ngIf="subscriptionForm.get('email')?.errors?.exist"
            >Déjà inscrit</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('gender').value }"
        >
          <label class="control-label"
            >{{ "GENDER" | translate }} <span class="required">*</span></label
          >
          <ng-select
            [items]="genderItems | crFormat"
            formControlName="gender"
            [bindLabel]="'name'"
            [bindValue]="'code'"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited && subscriptionForm.get('gender')?.status === 'VALID',
              'is-invalid':
                submited && subscriptionForm.get('gender')?.status === 'INVALID'
            }"
          ></ng-select>

          <span
            class="error-msg text-danger"
            *ngIf="submited && subscriptionForm.get('gender')?.errors?.required"
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('country').value }"
        >
          <label class="control-label"
            >{{ "COUNTRY" | translate }} <span class="required">*</span></label
          >
          <ng-select
            [items]="countrys | crFormat"
            formControlName="country"
            [bindLabel]="'name'"
            [bindValue]="'code'"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited && subscriptionForm.get('country')?.status === 'VALID',
              'is-invalid':
                submited &&
                subscriptionForm.get('country')?.status === 'INVALID'
            }"
          ></ng-select>

          <span
            class="error-msg text-danger"
            *ngIf="
              submited && subscriptionForm.get('country')?.errors?.required
            "
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('phone').value }"
        >
          <label class="control-label"
            >Mobile <span class="required">*</span></label
          >
          <input
            class="form-control"
            placeholder=""
            type="tel"
            formControlName="phone"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited && subscriptionForm.get('phone')?.status === 'VALID',
              'is-invalid':
                submited && subscriptionForm.get('phone')?.status === 'INVALID'
            }"
          />

          <span
            class="error-msg text-danger"
            *ngIf="submited && subscriptionForm.get('name')?.errors?.required"
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('society').value }"
        >
          <label class="control-label"
            >{{ "COMPANY" | translate }} <span class="required">*</span></label
          >
          <input
            class="form-control"
            placeholder=""
            type="text"
            formControlName="society"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited && subscriptionForm.get('society')?.status === 'VALID',
              'is-invalid':
                submited &&
                subscriptionForm.get('society')?.status === 'INVALID'
            }"
          />

          <span
            class="error-msg text-danger"
            *ngIf="
              submited && subscriptionForm.get('society')?.errors?.required
            "
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{
            'is-empty': !subscriptionForm.get('participantFunction').value
          }"
        >
          <label class="control-label"
            >{{ "POSITION" | translate }} <span class="required">*</span></label
          >
          <input
            class="form-control"
            placeholder=""
            type="text"
            formControlName="participantFunction"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited &&
                subscriptionForm.get('participantFunction')?.status === 'VALID',
              'is-invalid':
                submited &&
                subscriptionForm.get('participantFunction')?.status ===
                  'INVALID'
            }"
          />

          <span
            class="error-msg text-danger"
            *ngIf="
              submited &&
              subscriptionForm.get('participantFunction')?.errors?.required
            "
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('sector').value }"
        >
          <label class="control-label"
            >{{ "SECTOR" | translate }} <span class="required">*</span></label
          >
          <ng-select
            [items]="organizationActivities | crFormat"
            formControlName="sector"
            [bindLabel]="'name'"
            [bindValue]="'code'"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited && subscriptionForm.get('sector')?.status === 'VALID',
              'is-invalid':
                submited && subscriptionForm.get('sector')?.status === 'INVALID'
            }"
          ></ng-select>

          <span
            class="error-msg text-danger"
            *ngIf="submited && subscriptionForm.get('sector')?.errors?.required"
            >{{ "ERROR_REQUIRED" | translate }}</span
          >
        </div>
      </div>

      <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
        <div
          class="form-group label-floating"
          [ngClass]="{ 'is-empty': !subscriptionForm.get('website').value }"
        >
          <label class="control-label">{{ "WEB_SITE" | translate }}</label>
          <input
            class="form-control"
            placeholder=""
            type="text"
            formControlName="website"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited && subscriptionForm.get('website')?.status === 'VALID',
              'is-invalid':
                submited &&
                subscriptionForm.get('website')?.status === 'INVALID'
            }"
          />
        </div>
      </div>

      <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="form-group label-floating">
          <textarea
            class="form-control"
            formControlName="product_services"
            [placeholder]="'ABOUT_YOU' | translate"
            (focus)="focusedField($event)"
            (blur)="focusedField($event)"
            [ngClass]="{
              'is-valid':
                submited &&
                subscriptionForm.get('product_services')?.status === 'VALID',
              'is-invalid':
                submited &&
                subscriptionForm.get('product_services')?.status === 'INVALID'
            }"
          ></textarea>
        </div>
      </div>

      <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="img-upload-container">
          <div
            class="img-upload"
            [ngStyle]="{
              'background-image':
                'url(' + subscriptionForm.get('picture').value + ')'
            }"
          >
            <svg
              class="#olymp-plus-icon"
              *ngIf="!subscriptionForm.get('picture').value"
            >
              <use
                xlink:href="assets/svg-icons//sprites/icons.svg#olymp-plus-icon"
              ></use>
            </svg>
            <label for="eventCover"></label>
            <input
              id="eventCover"
              accept="image/*"
              type="file"
              (change)="pictuteChange($event)"
            />
          </div>
          <div>
            <h4>{{ "PROFILE_PICTURE" | translate }}</h4>
            <p>
              {{ "UPLOAD_INFO_1" | translate }} <br />
              200 x 200 pixels ; .jpg, .jpeg,. gif ou .png.
              {{ "UPLOAD_INFO_2" | translate }}
            </p>
            <button
              (click)="clearCover()"
              class="btn btn-smoke btn-md-2 btn-light-bg mb-0"
            >
              {{ "BUTTON.RESET" | translate }}
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                <div class="checkbox">
                    <label>
                        <input (change)="terms = !terms" type="checkbox" name="optionsCheckboxes"><span class="checkbox-material"><span class="check"></span></span>
                        {{'TERMS_Q' | translate}} <a [href]="'/general-conditions'" target="_blank">{{'TERMS_CONDITIONS' | translate}}</a>
                    </label>
                </div>
            </div> -->
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary btn-lg full-width mb-0" (click)="cancel()">
    {{ "BUTTON.RESET" | translate }}
  </button>
  <app-loader-dots
    [classList]="'btn btn-primary btn-lg full-width mb-0'"
    [label]="'BUTTON.SEND' | translate"
    [isLoading]="loaderSubscription"
    [isDisabled]="this.subscriptionForm.invalid"
    (clicked)="saveEvent()"
  >
  </app-loader-dots>
</div>
