<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'COMPANY' | translate}}s</h4>
    <span class="close" (click)="activeModal.close(null)">
        <svg class="olymp-close-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use></svg>
    </span>
</div>
<div class="modal-body">
    <div class="row user-list pt-30 mt-30">
        <div class="col-md-3 mb-15" *ngFor="let user of users">
            <div class="user-item ui-block" (click)="selectUser(user)"
                [ngClass]="{'selected-user':selectedUserID.includes(user.userId)}">
                <div class="user-thumb">
                    <img [src]="user.companyLogo ? user.companyLogo : '/assets/img/logo-placeholder.png'">
                </div>
                <div class="user-info">
                    <span class="h6 mb-1">{{user.society}}</span>
                    <small>{{user.country | crValue:'country'}}</small>
                </div>
            </div>
        </div>
    </div>

    <ngb-pagination *ngIf="users?.length && userCount > paginationArgs.pageSize" class="pagination__count"
        [collectionSize]="userCount" [page]="paginationArgs.pageNumber" [pageSize]="paginationArgs.pageSize" [maxSize]="5"
        [boundaryLinks]="false" (pageChange)="changePage($event)">
        <ng-template ngbPaginationPrevious><i class="fas fa-angle-left"></i></ng-template>
        <ng-template ngbPaginationNext><i class="fas fa-angle-right"></i></ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
</div>

<div class="modal-footer text-right">
    <button class="btn btn-secondary btn-lg mb-0" (click)="cancel()">{{'BUTTON.RESET' | translate}}</button>
    <app-loader-dots 
        [classList]="'btn btn-primary btn-lg mb-0'" 
        [label]="'BUTTON.ADD' | translate " 
        [isLoading]="loaderSubscription" 
        (clicked)="select()">
    </app-loader-dots>
</div>