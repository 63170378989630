<div class="modal-header">
    <h6 (click)="helpType(null)" class="modal-title uppercase-first-letter cursor-pointer" id="modal-basic-title"><i *ngIf="helpForm.get('type').value" class="fas fa-chevron-left mr-3"></i> {{helpForm.get('type').value ? (helpForm.get('type').value | translate) : ('HELP_CENTER' | translate)}}</h6>
    <span class="close" (click)="activeModal.close(null)">
        <svg class="olymp-close-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use></svg>
    </span>
</div>
<div class="modal-body">

    <div class="category-help" *ngIf="!helpForm.get('type').value">
        <h3 class="text-center">{{'HELP_CENTER_MSG' | translate}}</h3>
        <div class="row mb-5 mt-4">
            <div class="col col-xl-4 col-lg-4 col-md-4 col-sm-1 col-12">
                <div class="ui-block" (click)="helpType('TECHNICAL')">
                    <div _ngcontent-aar-c185=""><img src="/assets/img/Ticket.svg"></div>
                    <h6 class="title">{{'TECHNICAL' | translate}}</h6>
                </div>
            </div>

            <div class="col col-xl-4 col-lg-4 col-md-4 col-sm-1 col-12">
                <div class="ui-block" (click)="helpType('ADMINISTRATIVE')">
                    <div _ngcontent-aar-c185=""><img src="/assets/img/Ticket.svg"></div>
                    <h6 class="title">{{'ADMINISTRATIVE' | translate}}</h6>
                </div>
            </div>

            <div class="col col-xl-4 col-lg-4 col-md-4 col-sm-1 col-12">
                <div class="ui-block" (click)="helpType('SUGGESTION')">
                    <div _ngcontent-aar-c185=""><img src="/assets/img/Suggestion.svg"></div>
                    <h6 class="title">{{'SUGGESTION' | translate}}</h6>
                </div>
            </div>
        </div>
    </div>

    <div class="form-help" *ngIf="helpForm.get('type').value">
        <form [formGroup]="helpForm">
            <div class="form-group label-floating" [ngClass]="{'is-empty': !helpForm.get('title').value}">
                <label class="control-label">{{'SUBJECT' | translate}} <span class="required">*</span></label>
                <input class="form-control" placeholder="" formControlName="title" type="text" (focus)="focusedField($event)" (blur)="focusedField($event)">
            </div>
        
            <div class="form-group label-floating" [ngClass]="{'is-empty': !helpForm.get('content').value}">
                <label class="control-label">{{'MESSAGE' | translate}} <span class="required">*</span></label>
                <textarea class="form-control" placeholder="" formControlName="content" type="text" (focus)="focusedField($event)" (blur)="focusedField($event)"></textarea>
            </div>
        
            <div class="actions">
                <!-- <button class="btn btn-primary btn-md-2 mb-0" (click)="send()">
                    {{'BUTTON.SEND' | translate}}
                </button> -->
                <app-loader-dots 
                    [classList]="'btn btn-primary btn-md-2 mb-0'" 
                    [label]="'BUTTON.SEND' | translate" 
                    [isDisabled]="helpForm.status == 'INVALID'"
                    [isLoading]="loading" 
                    (clicked)="send()">
                </app-loader-dots>
            </div>
        </form>
    </div>

    <div class="coppyright">
        <span>Made with 🧡 by <a href="https://neocortex-technologies.com" target="_blank">Neocortex Technologies</a></span>
    </div>
</div>