import { Component, Input, OnInit } from '@angular/core';
import { Events } from '../../models/event.model';
import moment from 'moment';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  @Input() event:Events;
  constructor() { }

  ngOnInit(): void {
  }

  convertDate(date){
    const userLang: string = localStorage.getItem('lang');
    
    if(date){
      return moment(date).locale(userLang).format('MMMM Do, YYYY');
    } else{
      return '';
    }
  }

}
