import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Events } from 'src/app/shared/models/event.model';
import {
  CRUDModel,
  PaginationArgs,
} from 'src/app/shared/models/pagination.args.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AcpAccountService {
  public apiUrl = environment.apis.baseUrl;
  paginationArgs: PaginationArgs = { pageNumber: 1, pageSize: 10 };

  constructor(private http: HttpClient) {}

  getNews(data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(this.apiUrl + `/news`, data, { headers });
  }

  getNewsById(id): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(this.apiUrl + `/news/`+id, { headers });
  }

  getEvent(): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/event/filter', {});
  }

  getAssets(): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/assets/operations/filter`, {});
  }

  getDescription(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(this.apiUrl + `/config/description`, { headers });
  }

  getDirectory(filter, paginationArgs = this.paginationArgs): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);

    return this.http.post<any>(this.apiUrl + `/entreprise/filter`, filter, {
      headers,
      params,
    });
  }

  eventsFilter(
    paginationArgs = this.paginationArgs,
    filterObject = {},
    sort = { order_by: 'startingDate', order_by_sorting: 'ASC' }
  ) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);
    return this.http.post<CRUDModel<Events>>(
      this.apiUrl + '/events/acp',
      filterObject,
      { headers }
    );
  }

  getCRvalues(type, paginationArgs, filter = {}): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);

    return this.http.post<any>(this.apiUrl + `/${type}/`, filter, {
      headers,
      params,
    });
  }

  addOpportunity(data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    return this.http.post<any>(this.apiUrl + `/opportunity`, data, { headers });
  }

  updateOpportunity(opportunityId, data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    return this.http.put<any>(
      this.apiUrl + `/opportunity/${opportunityId}`,
      data,
      { headers }
    );
  }

  deleteOpportunity(opportunityId): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    return this.http.delete<any>(
      this.apiUrl + `/opportunity/${opportunityId}`,
      { headers }
    );
  }

  getOpportunitys(
    filter = {},
    paginationArgs = this.paginationArgs,
    source = 'filter'
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);

    return this.http.post<any>(this.apiUrl + `/opportunity/${source}`, filter, {
      headers,
      params,
    });
  }

  getOpportunityByID(opportunityId, filter = {}): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(
      this.apiUrl + `/opportunity/${opportunityId}`,
      filter,
      { headers }
    );
  }

  getOpportunityMedia(opportunityId): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(
      this.apiUrl + `/opportunity/media/${opportunityId}`, {},
      { headers }
    );
  }

  getRelatedOpportunity(opportunityId): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(
      this.apiUrl + `/opportunity/related/${opportunityId}`,
      { headers }
    );
  }

  getEventByID(id) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<CRUDModel<Events>>(this.apiUrl + '/event/' + id, {
      headers,
    });
  }

  geteventDescription(id) {
    return this.http.get<any>(this.apiUrl + '/event/description/' + id);
  }
}
