import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../services/user.service';
import moment from 'moment';
import { NewsDetailsComponent } from '../../modals/news-details/news-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements OnInit {

  @Input() news;
  @Input() adminView;
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() updateStatus = new EventEmitter();

  constructor(
    private userService: UserService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  updateEvent() {
    this.update.emit(this.news)
  }

  deleteEvent() {
    this.delete.emit(this.news.id)
  }

  toggleStatus() {
    this.news.status = this.news.status ? false : true
    this.updateStatus.emit(this.news)
  }

  openNews(data){
    const modalRef = this.modalService.open(
      NewsDetailsComponent, {
      size: 'xl',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.news = data;
    modalRef.result.then((result) => {
    });
  }

  convertDate(date) {
    let userLang: string = localStorage.getItem('lang');
    if (date) {
      return moment(date).locale(userLang).format('MMMM Do, YYYY');
    } else {
      return '';
    }
  }

}
