import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from 'src/app/shared/config/translate.config';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  @Output() selectLang = new EventEmitter();
  public languages = SUPPORTED_LANGUAGES;
  public selectedLanguage: string = 'fr';

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

  public toggleLang(lang: any) {
    this.selectedLanguage = lang.code;
    this.selectLang.emit(lang.code)
  }
}
