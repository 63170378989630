import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() user;
  @Input() cardType:string = 'user';
  @Input() existAppointments;
  @Output() profile = new EventEmitter();
  @Output() appointment = new EventEmitter();

  eventID;
  connectedUser: any;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.connectedUser = JSON.parse(localStorage.getItem('me'))
    this.route.parent.params.subscribe(params => {
      if (params['id']) {
        this.eventID = params['id'] ;
      }
    })
  }

  appointmentRequest(user){
    this.appointment.emit(user)
  }

  viewProfile(user){
    this.profile.emit(user)
  }

  chatRoom(id){
    if(this.cardType == 'company'){
      this.router.navigate(['/account/chat'], {queryParams: {ref: id}});
    } else {
      this.router.navigate(['/room/event/'+this.eventID+'/chat'], {queryParams: {ref: id}});
    }
  }

}
