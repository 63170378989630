import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { PaginationArgs } from '../../models/pagination.args.model';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {

  public users;
  public filters = {
    attributs: ["companyLogo", "society", "userId", "country"],
    showInACP : false 
  };
  public userCount;
  public loaderSubscription: boolean =  false;
  public paginationArgs: PaginationArgs = { pageNumber: 1, pageSize: 16 };

  selectedUserID = []

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getUsers(this.filters)
  }

  getUsers(filter){
    this.userService.getUsers(filter, this.paginationArgs).subscribe(res => {
      this.users = res.Data;
      this.userCount = res.numberMaxRequest;
    })
  }

  changePage(page){
    this.paginationArgs.pageNumber = page;
    this.userService.getUsers(this.filters, this.paginationArgs).subscribe(res => {
      this.users = res.Data;
      this.userCount = res.numberMaxRequest;
    })
  }

  selectUser(user){
    if(this.selectedUserID.includes(user.userId)){
      this.selectedUserID.splice(this.selectedUserID.findIndex(id => id == user.userId), 1)
    } else {
      this.selectedUserID.push(user.userId)
    }
  }

  cancel(){
    this.selectedUserID = []
  }

  select(){
    this.activeModal.close(this.selectedUserID)
  }

}
