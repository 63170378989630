<article class="hentry blog-post">
				
    <div class="post-thumb">
        <img [src]="event.imageCover" alt="photo">
    </div>

    <div class="eventCard__content">
        <div class="py-3 px-4">
            <div class="eventCard__inner">
                <h4 class="post-title">{{event.name}}</h4>
                <div class="event-info">
                    <div class="event-date"><i class="far fa-calendar-alt"></i> {{convertDate(event.startingDate)}}</div>
                    <div class="event-place" [ngbTooltip]="event.location" tooltipClass="tooltipClass"><i class="fas fa-map-marker-alt"></i> {{event.location}}</div>
                    
                    <a [routerLink]="'/events/'+event.id" class="btn btn-primary btn-sm mb-0">{{'DETAILS' | translate}}<div class="ripple-container"></div></a>
                </div>
            </div>
        </div>
    </div>

</article>