import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscribedEventGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Use the API service to check if the user is authenticated
    return this.userService.getEventByUser().pipe(
      map((res) => {
        if (res['Data'].find(item => item.id == route.params['id'])) {
          return true; // User is authenticated, allow access
        } else {
          this.router.navigate(['/room']); // User is not authenticated, navigate to login page
          return false;
        }
      })
    );
  }

}
