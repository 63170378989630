<div class="header-language-wrapper d-inline-block" ngbDropdown>
    <a class="nav-link" ngbDropdownToggle id="navbarDropdown" role="button">
        <span>
            <img class="flag" [src]="'/assets/svg-icons/' + translate.currentLang + '.svg'" [alt]="translate.currentLang | titlecase"/>
            <svg class="olymp-dropdown-arrow-icon chevron-lang-icon">
                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon"></use>
            </svg>
        </span>
    </a>
    <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="language-dropdown-list">
        <span *ngFor="let lang of languages" [class.active]="lang.code === translate.currentLang">
            <a ngbDropdownItem (click)="toggleLang(lang)">
                <img class="flag" [src]="'/assets/svg-icons/' + lang?.code + '.svg'" [alt]="lang?.name.toUpperCase() | translate" />
                <span class="text-lang">{{lang?.name | translate}}</span>
            </a>
        </span>
    </div>
</div>