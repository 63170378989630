<div class="modal-header">
    <!-- <h6 class="modal-title uppercase-first-letter" id="modal-basic-title">{{news?.title | crTranslator}}</h6> -->
    <span class="close" (click)="activeModal.close(null)">
        <svg class="olymp-close-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use></svg>
    </span>
</div>


<div class="modal-body">
  
  <div class="row">
    <div class="col-md-6">
      <img [src]="news.image" class="w-100">
    </div>
    <div class="col-md-6 d-flex justify-content-start align-items-start flex-column">
      <div class="ui-block-title">
        <h5>Title: {{news?.title | crTranslator}}</h5>
      </div>
      <!-- attachements -->
    <div  *ngIf="news.attached_files?.length">
      <div class="ui-block-title">
        <h5>Attachements</h5>
      </div>
      <div class="attached-files-list">
        <span
          class="attached-files-item"
          *ngFor="let file of news.attached_files; let i = index"
        >
          <span
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
            [style.width.%]="file.progress"
          ></span>
          <a [href]="file.content" target="_blank">
            <img
              [src]="'/assets/img/file-extession/' + file.extension + '.png'"
            />{{ file.name }}
          </a>
        </span>
      </div>
    </div>
    </div>
  </div>
    <!-- description -->
    <div class="row mt-5">
      <div class="col-12">
        <div *ngIf="news.description[0].value || news.description[1].value">
            <div class="ui-block-title">
                <h5>Description</h5>
              </div>
            <div [innerHTML]="news.description | crTranslator"></div>
        </div>
      </div>
    </div>

    <!-- attachements -->
    <!-- <div  *ngIf="news.attached_files?.length">
      <div class="ui-block-title">
        <h5>Attachements</h5>
      </div>
      <div class="attached-files-list">
        <span
          class="attached-files-item"
          *ngFor="let file of news.attached_files; let i = index"
        >
          <span
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
            [style.width.%]="file.progress"
          ></span>
          <a [href]="file.image" target="_blank">
            <img
              [src]="'/assets/img/file-extession/' + file.extension + '.png'"
            />{{ file.name }}
          </a>
        </span>
      </div>
    </div> -->
</div>
