<app-header></app-header>

<div
  class="d-flex flex-column justify-content-center align-items-center vh-100 w-100 text-center"
>
  <div
    *ngIf="status == 'SUCCESS'"
    class="d-flex flex-column justify-content-center align-items-center text-center bg-white card-success-container"
  >
    <svg
      class="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path
        class="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
    <h2 class="success-text mt-4">Payment Success</h2>
    <p class="px-5">Thank you for your payment.</p>
    <a class="btn btn-success" [href]="this.redirectTo"> Back </a>
    <p>Redirect automaticlly in {{ timer }}</p>
  </div>

  <div
    *ngIf="status == 'PENDING'"
    class="d-flex flex-column justify-content-center align-items-center text-center bg-white card-pending-container"
  >
    <div class="loader"></div>
    <h2 class="warning-text mt-4">Payment is pending</h2>
    <p class="px-5">
      Your account have been created, Waiting to confirm your registration from
      administration
    </p>
    <button class="btn btn-pending" (click)="reload()">Refresh</button>
  </div>
</div>
