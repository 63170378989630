import { Pipe, PipeTransform } from '@angular/core';
import { map, retry, catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Pipe({
  name: 'getUser'
})
export class UserPipe implements PipeTransform {
  constructor(private userService:UserService){}

  transform(id: any) {
    if(id){
      return this.userService.getUserByID(id).pipe(
        map(res => {        
          return res.Data[0].first_name + ' ' + res.Data[0].last_name
        }),
        retry(2), catchError((err: Error) => {
          return null;
        })
      );
    }
  }

}
