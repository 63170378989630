<ngb-carousel
  *ngIf="slides"
  [showNavigationArrows]="showNavigationArrows"
  [showNavigationIndicators]="showNavigationIndicators"
  [interval]="5000"
>
  <ng-template ngbSlide *ngFor="let slide of slides; let index = index">
    <div class="picsum-img-wrapper">
      <img
        (click)="carouselAction(slide, index)"
        [title]="slide.name"
        class="d-block w-100"
        [placement]="'top'"
        [src]="slide.image"
        [alt]="slide.name"
        [ngClass]="{ 'cursor-pointer': href || openModal }"
      />
    </div>
  </ng-template>
</ngb-carousel>
