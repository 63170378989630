<div *ngIf="show">
  <div
    class="alert alert-primary d-flex justify-content-center align-items-center"
    role="alert"
    *ngIf="loading"
  >
    <span class="loader mr-1"></span>
    {{ "WARNING_BAR.LOADING_MESSAGE" | translate }}
  </div>

  <div
    class="alert alert-success d-flex justify-content-center align-items-center"
    role="alert"
    *ngIf="valide"
  >
    <span class="mr-1"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-circle-check-filled"
        width="21"
        height="21"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
          stroke-width="0"
          fill="currentColor"
        /></svg
    ></span>

    <div>
      <span> {{ "WARNING_BAR.VALIDATION_MESSAGE" | translate }} </span
      >{{ "WARNING_BAR.VALIDATION_SUBMESSAGE" | translate }}
    </div>
  </div>

  <div class="warning-message py-2" *ngIf="!valide && !loading">
    <p class="mb-0">
      <span class="mr-1" style="vertical-align: middle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-alert-triangle-filled"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm.01 13.33l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -7a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"
            stroke-width="0"
            fill="currentColor"
          /></svg
      ></span>
      <span>{{ "WARNING_BAR.WARNING" | translate }} :</span>
      {{ "WARNING_BAR.WARNING_MESSAGE" | translate }}
      <span
        >{{
          this.connectedUser.remainingDays == 0
            ? ("TODAY" | translate)
            : this.connectedUser.remainingDays + " " + ("DAYS" | translate)
        }}
      </span>
      , {{ "WARNING_BAR.WARNING_SUBMESSAGE" | translate }},
      <a class="link" (click)="GoToPay()">{{
        "WARNING_BAR.CLICK_HERE" | translate
      }}</a>
    </p>
  </div>
</div>
