<div class="container">
    <img src="/assets/svg-icons/session_timeout.svg">

    <div class="modal-body">
       <h4>{{'SESSION_EXPIRED.TITLE' | translate}}</h4>
       <p>{{'SESSION_EXPIRED.MESSAGE' | translate}}</p>
    
       <a class="btn btn-lg btn-primary" (click)="reconnexion()">{{'SESSION_EXPIRED.BUTTON' | translate}}</a>
    </div>
</div>
