<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'IMAGE_CROPPING' | translate}}</h4>
    <span class="close" (click)="activeModal.close('Cross click')">
        <svg class="olymp-close-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use></svg>
    </span>
</div>

<div class="modal-body progress-cropper-bar">
    <div class="crop">
        <angular-cropper #angularCropper [cropperOptions]="config" [imageUrl]="srcFile"></angular-cropper>
    </div>


    <div class="view-crop progress-cropper-bar">
        <div *ngIf="uploadForm?.getRawValue()?.progress" class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
                aria-valuemin="0" aria-valuemax="100" [style.width.%]="uploadForm?.getRawValue().progress">
            </div>
        </div>
        <img [src]="imgUrl" [ngClass]="{'progress-visible':uploadForm?.getRawValue()?.progress}" />
    </div>
</div>

<div class="modal-footer pt-3 pb-3">
    <button type="button" [disabled]="!imgUrl || uploadForm?.getRawValue().progress"
        class="btn btn-primary btn-md-2 mb-0" (click)="saveFile()">
        Appliquer
    </button>
</div>