<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{mediaList[index]?.name}}</h4>
    <span class="close" (click)="activeModal.close('Cross click')">
        <svg class="olymp-close-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use></svg>
    </span>
</div>
<div class="modal-media-container">
    <ng-container *ngIf="mediaList">
        <div class="media-img">
            <a [attr.href]="mediaList[index]?.link ? ouvrirLienExterne(mediaList[index]?.link):null" target="_blank">
                <img [src]="(mediaList[index]?.image)" alt="photo">
            </a>
        </div>
    
        <div class="arrow-left" *ngIf="index != 0" (click)="prevMedia()">
            <svg class="olymp-popup-left-arrow">
                <use xlink:href="/assets/svg-icons/sprites/icons.svg#olymp-popup-left-arrow"></use>
            </svg>
        </div>
        <div class="arrow-right" *ngIf="index != mediaList.length - 1" (click)="nextMedia()">
            <svg class="olymp-popup-right-arrow">
                <use xlink:href="/assets/svg-icons/sprites/icons.svg#olymp-popup-right-arrow"></use>
            </svg>
        </div>
    </ng-container>

    <div class="thumbnail">
        <div class="thumbnail-items">
            <ng-container *ngFor="let thumbnail of mediaList ; let i = index">
                <div [class]="'thumbnail-item thumbnail-item-'+i">
                    <div></div>
                    <div class="media-thumbnail media-thumbnail-img"
                        (click)="goToMedia(i)">
                        <img [src]="(thumbnail?.image)" alt="photo">
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>