import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.scss']
})
export class CarrouselComponent implements OnInit {

  @Output() openSlide = new EventEmitter();
  @Input() slides;
  @Input() showNavigationArrows = false;
  @Input() showNavigationIndicators = false;
  @Input() href;
  @Input() openModal;

  constructor( config: NgbCarouselConfig ) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit(): void {
  }

  carouselAction(slide, index) {
    if (this.href) {
      window.open(slide.link, '_blank')
    } else if (this.openModal) {
      this.openSlide.emit(index);
    }
  }

}
