import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthAcpGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      sessionStorage.getItem('token') &&
      sessionStorage.getItem('enable') == 'true' &&
      (sessionStorage.getItem('role') == 'ADMIN' ||
        sessionStorage.getItem('role') == 'ACP_SUBSCRIBER')
    ) {
      return true;
    } else {
      this.router.navigate(['/authentication']);
      sessionStorage.clear();
      localStorage.removeItem('me');
      return false;
    }
  }
}
