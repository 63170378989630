<div *ngIf="albums && albums?.length" class="post-carrousel-wrapper single-post-slider mb-xl">
    <ngb-carousel #mycarousel [activeId]="activeSliderId" (slide)="onSlide($event)"
        [showNavigationIndicators]="albums?.length >1" [showNavigationArrows]="albums?.length >1">
        <ng-template ngbSlide [id]="'slideId_' + i" *ngFor="let media of albums; index as i">
            <div class="picsum-img-wrapper">
                <img [src]="media?.content" />
            </div>
        </ng-template>
    </ngb-carousel>
    <ul class="slider-slides carousel-thumbnail">
        <li class="carousel-thumbnail__item list-inline-item" *ngFor="let thumb of albums; index as i"
            (click)="cycleToSlide(i)">
            <a class="slides-item" [class.slide-active]="'slideId_' + i === currentSlide">
                <img class="carousel-thumbnail__img" [src]="thumb?.content" />
                <div class="overlay overlay-dark"></div>
            </a>
        </li>
    </ul>
</div>