import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { Meta } from '@angular/platform-browser';
import { AuthEventGuard } from './shared/guard/auth-event.guard';
import { AuthAcpGuard } from './shared/guard/auth-acp.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'portal',
    loadChildren: () =>
      import('./portal/portal.module').then((m) => m.PortalModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'room',
    loadChildren: () => import('./room/room.module').then((m) => m.RoomModule),
    canActivate: [AuthEventGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./acp-account/acp-account.module').then(
        (m) => m.AcpAccountModule
      ),
    canActivate: [AuthAcpGuard],
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'resetpassword',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'b2b/:token',
    loadChildren: () =>
      import('./decision-mail/decision-mail.module').then(
        (m) => m.DecisionMailModule
      ),
  },
  {
    path: 'not-founded-event',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'general-conditions',
    loadChildren: () =>
      import('./terms-condtions/terms-condtions.module').then(
        (m) => m.TermsCondtionsModule
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private meta: Meta) {
    this.meta.updateTag({
      property: 'og:title',
      content: 'Africa CEO Platform',
    });
    /* this.meta.updateTag({ property: 'og:description', content: 'Description de la route' });
    this.meta.updateTag({ property: 'og:image', content: 'URL de l\'image à utiliser' });
    this.meta.updateTag({ property: 'og:url', content: 'URL de la route' });
    this.meta.updateTag({ property: 'og:type', content: 'website' }); */

    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Africa CEO Platform',
    });
    /* this.meta.updateTag({ name: 'twitter:description', content: 'Description de la route' });
    this.meta.updateTag({ name: 'twitter:image', content: 'URL de l\'image à utiliser' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' }); */
  }
}
