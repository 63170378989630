import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'linkPattern'
})
export class LinkPatternPipe implements PipeTransform {

  constructor(
    private sanitized: DomSanitizer
  ) { }

  transform(value: string) {
    if (value) {
      const self = this;
      let replacedText;
      try {
        const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        const replacePattern3 = /^((?!@).)*([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/igm;

        replacedText = value.replace(replacePattern1, data => {
          return this.deletProtocolUrl(data);
        });
        
        replacedText = replacedText.replace(
          replacePattern2,
          '$1<a href="http://$2" target="_blank" title="$2">$2</a>'
        );

        replacedText = replacedText.replace(
          replacePattern3, data => {
            return this.getEmail(data);
          });
      } catch (e) {
        console.warn(
          'Error while addig link from string value. Details: ',
          e
        );
        replacedText = value;
      }
      return this.sanitized.bypassSecurityTrustHtml(replacedText);
    }
  }

  getEmail(data: string) {
    return '<a href="mailto:' + data + '">' + data + '</a>'
  }

  deletProtocolUrl(value){
    let url

    if (value && value.startsWith('http://')) {
      url = value.substring(7);
    } else if (value && value.startsWith('https://')) {
      url = value.substring(8);
    } else {
      url = value
    }
    
    return url
  }

}
