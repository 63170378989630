import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trancateTime'
})
export class TrancateTimePipe implements PipeTransform {

  constructor() { }

  transform(value: any): string {
    if(value){
      return value.length == 8 ? value.substring(0,5) : value.substring(0,4)
    }
    return ''
  }

}
