import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const TRANSLATE_LOADER_CONFIGS: any = {
    defaultLanguage: 'fr',
    loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
    },
};

export const DEFAULT_LANGUAGE = 'fr';
export const TRANSLATION_LANGUAGES = /fr|en/;
export const SUPPORTED_LANGUAGES: any[] = [
    { code: 'en', name: 'ENG', local: 'en-EN' },
    { code: 'fr', name: 'FR', local: 'fr-FR' }
];
export const DEFAULT_DATE_FORMAT = 'EEEE, MMMM d, y' || 'shortTime';
export const DEFAULT_DATE_FORMAT_INPUT = {
    fr: 'DD/MM/YYYY',
    en: 'MM/DD/YYYY',
};
export const I18N_VALUES = {
    en: {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    fr: {
        weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc',],
    }
    // other languages you would support
};

export const languageByCode = (code: string) => {
    let result = SUPPORTED_LANGUAGES.filter((data) => data.code === code);
    result = result ? result : SUPPORTED_LANGUAGES.filter((data) => data.code === DEFAULT_LANGUAGE);
    return result.length ? result[0].local : 'fr-FR'
}

export const setLocale = (userLang) => registerLocaleData(userLang === 'fr' ? localeFr : localeEn, languageByCode(userLang));