import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PublicFilesService } from '../../services/public-files.service';
import { UploadFilesService } from '../../services/upload-files.service';

@Component({
    selector: 'app-cropper-modal',
    templateUrl: './cropper-modal.component.html',
    styleUrls: ['./cropper-modal.component.scss']
})
export class CropperModalComponent implements OnInit {
    @Input() aspectRatio = 16 / 9;
    @Input() srcFile;
    @Input() public fileName: string;
    @Input() resizeToWidth = 512;
    @Input() isPublic: boolean = false;
    public uploadForm: FormGroup;
    croppedImage: any = '';
    imgFile: File;
    submitCropp: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private uploadFilesService: UploadFilesService,
        private publicFilesService: PublicFilesService) { }

    ngOnInit(): void {
        this.initForm();
    }

    dataURLtoFile(dataurl, filename) {
        const arr = dataurl?.split(',');
        let mimeStr = arr[0]?.match(/:(.*?);/);
        const mime = mimeStr ? mimeStr[1] : '';
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    private initForm() {
        return (this.uploadForm = this.formBuilder.group({
            progress: new FormControl({ value: 0, disabled: false })
        }))
    }

    validateFile(control: AbstractControl) {
        if (control.value && control.value instanceof File) {
            if(this.isPublic){
                return this.publicFilesService.uploadFile(control.value, true)
                .pipe(
                    map(res => {
                        if (res?.length && res[0]?.id) {
                            control.setValue(res[0]?.id);
                            control?.parent.get('progress')?.setValue(null);
                            this.activeModal.close({imgUrl: this.croppedImage, imgFileId: this.uploadForm.value.media });
                            return null;
                        } else {
                            control?.parent?.get('progress')?.setValue(res ? res : 0);
                            return { uploading: true };
                        }
                    })
                );
            }else{
                return this.uploadFilesService.uploadFile(control.value, true)
                .pipe(
                    map(res => {
                        if (res?.length && res[0]?.id) {
                            control.setValue(res[0]?.id);
                            control?.parent.get('progress')?.setValue(null);
                            this.activeModal.close({imgUrl: this.croppedImage, imgFileId: this.uploadForm.value.media });
                            return null;
                        } else {
                            control?.parent?.get('progress')?.setValue(res ? res : 0);
                            return { uploading: true };
                        }
                    })
                );
            }
            
        }
        return of(null);
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.imgFile = this.dataURLtoFile(event.base64, this.fileName);
    }
    
    imageLoaded(event) {
        // show cropper        
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    public saveFile() {
        if (this.croppedImage) {
            this.submitCropp = true
            this.uploadForm = this.formBuilder.group({
                media: new FormControl(this.imgFile, [], this.validateFile.bind(this)),
                progress: new FormControl({ value: 0, disabled: false })
            })
        }
    }

}
