import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { FailedPaymentComponent } from './failed-payment/failed-payment.component';
import { LayoutHomeComponent } from '../layouts/components/layout-home/layout-home.component';
import { RenewSuccessPaymentComponent } from './renew-success-payment/renew-success-payment.component';
import { RenewFailedPaymentComponent } from './renew-failed-payment/renew-failed-payment.component';
import { LayoutPortalComponent } from '../layouts/components/layout-portal/layout-portal.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'success',
        component: SuccessPaymentComponent,
      },
      {
        path: 'failed',
        component: FailedPaymentComponent,
      },
    ],
  },
  {
    path: 'renew',
    children: [
      {
        path: 'success',
        component: RenewSuccessPaymentComponent,
      },
      {
        path: 'failed',
        component: RenewFailedPaymentComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentRoutingModule {}
