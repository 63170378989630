import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { HelpCenterService } from '../../services/help-center.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-help-form',
  templateUrl: './help-form.component.html',
  styleUrls: ['./help-form.component.scss']
})
export class HelpFormComponent implements OnInit {

  helpForm: FormGroup;

  public loading:boolean = false;

  constructor(
    private formBuilder: FormBuilder, 
    private helpCenterService: HelpCenterService,
    private toastService: ToastService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.helpForm = this.formBuilder.group({
      title: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),      
    });
  }

  helpType(event){
    this.helpForm.get('type').setValue(event)
  }

  focusedField(event) {
    if (event.type == 'focus') {
      event.target.closest('.label-floating').classList.add('is-focused');
    } else {
      event.target.closest('.label-floating').classList.remove('is-focused');
    }
  }
  
  send(){
    if(this.helpForm.valid){
      this.loading = true;
      this.helpCenterService.sendMessageSupport(this.helpForm.value).subscribe(res => {
        this.toastService.success();
        this.activeModal.close();
      }, error => {
        this.toastService.error()
        this.loading = false;
      })
    }
  }

}


