import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'status',
  pure: false
})
export class StatusPipe implements PipeTransform {
  status: any = [
    {
      label: this.translate.instant('ACCEPTED'),
      value: 'ACCEPTED'
    },
    {
      label: this.translate.instant('INPROGRESS'),
      value: 'INPROGRESS'
    },
    {
      label: this.translate.instant('REFUSED'),
      value: 'REFUSED'
    },
    {
      label: this.translate.instant('NOT_SUBSCRIBED'),
      value: 'NOT_SUBSCRIBED'
    }
  ];;


  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang)
        this.status = [
          {
            label: this.translate.instant('ACCEPTED'),
            value: 'ACCEPTED'
          },
          {
            label: this.translate.instant('INPROGRESS'),
            value: 'INPROGRESS'
          },
          {
            label: this.translate.instant('REFUSED'),
            value: 'REFUSED'
          },
          {
            label: this.translate.instant('NOT_SUBSCRIBED'),
            value: 'NOT_SUBSCRIBED'
          }
        ];

    });
  }
  
  transform(value: string) {
    return value ? this.status.find(role => role.value == value)?.label : '';
  }

}
